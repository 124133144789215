@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;
@import './marketoform.scss';

html {
  font-family: v.$font-family;
  scroll-behavior: smooth;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  // Color keyword vars
  --indigo: #{v.$indigo};
  --blue: #{v.$blue};
  --dark-blue: #{v.$dark-blue};
  --magenta: #{v.$magenta};
  --dark-magenta: #{v.$dark-magenta};
  --orange: #{v.$orange};
  --dark-yellow: #{v.$dark-yellow};
  --dark-teal: #{v.$dark-teal};
  --purple: #{v.$purple};
  --green-blue: #{v.$green-blue};
  --light-pink: #{v.$light-pink};
  --peach: #{v.$peach};
  --light-yellow: #{v.$light-yellow};
  --light-teal: #{v.$light-teal};
  --light-purple: #{v.$light-purple};
  --light-blue: #{v.$light-blue};
  --teal: #{v.$teal};
}

.light,
.Event {
  --background: #efefef;
  --header-color-bg: #{v.$white};
  --header-color-shadow: #{v.$light-gray};
  --text-color-primary: #{v.$blue};
  --text-color-fade: #{v.$gray};
  --text-color-secondary: #{v.$dark-gray};
  --text-color-highlight: #{v.$blue};
  --card-bg-primary: #{v.$lighter-gray};
  --card-bg-highlight: #{v.$white};
  --card-bg-secondary: #{v.$light-blue};
  --card-bg-third: #{v.$white};
  --card-bg-third-hover: #{v.$light-gray};
  --footer-color-highlight: #{v.$indigo};
  --footer-color-primary: #{v.$indigo};
  --outline-secondary: #{v.$blue};
  --iframe-bg: #{v.$white};
  --shadow-color: #{v.$peach};
  --cta-outline-text: #{v.$white};
}

.dark {
  --background: #{v.$blue};
  --header-color-bg: #{v.$blue};
  --header-color-shadow: #{v.$dark-blue};
  --text-color-primary: #{v.$white};
  --text-color-fade: #{v.$light-gray};
  --text-color-secondary: #{v.$lead};
  --text-color-highlight: #{v.$blue};
  --card-bg-primary: #{v.$white};
  --card-bg-highlight: #{v.$blue};
  --card-bg-secondary: #{v.$light-gray};
  --card-bg-third: #{v.$indigo};
  --card-bg-third-hover: #{v.$dark-blue};
  --footer-color-highlight: #{v.$white};
  --footer-color-primary: #{v.$indigo};
  --outline-secondary: #{v.$light-gray};
  --iframe-bg: #{v.$lighter-gray};
  --shadow-color: #{v.$magenta};
  --cta-outline-text: #{v.$blue};
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--background);
  color: var(--text-color-primary);
}

.storybookBody {
  background-color: var(--background);
  color: var(--text-color-primary);
  width: 100%;
  padding: 20px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

// Dont show focus outline until tabbed
:focus:not(:focus-visible) {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
}

input {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

button,
a {
  @include m.hover {
    &:hover {
      cursor: pointer;
    }
  }
}

p {
  margin: 0;

  + p {
    margin-top: 1em;
  }
}

img,
svg {
  max-height: 100%;
  max-width: 100%;
}

// disable the iframe created by CRA
body > iframe {
  pointer-events: none;
}

.disable-scroll {
  overflow: hidden;
  // margin-right: var(--scrollbar-width, 16px);
}

.basic-page {
  margin: 0 auto;
  max-width: 800px;
  padding: 100px;
  @include m.richtext;

  h1 {
    @include t.headline3;
  }

  a {
    color: var(--text-color-primary);
    text-decoration: underline;
  }
}
