@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.cards {
  @include m.cards;
}

.card {
  height: 100%;
}

.title {
  @include t.subtitle;

  font-weight: 700;
}

.description {
  @include t.cta-text-bold;
}

.byline {
  @include t.eyebrow;

  grid-column: 1/ -1;
}

.logo {
  width: 120px;
  height: auto;
  max-height: 48px;
  margin-top: 12px;

  img {
    // turn to white for dark bg
    :global(.dark) & {
      filter: brightness(0) invert(1);
    }
  }
}

.backBtn {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @include m.breakpoint(v.$lg) {
    justify-content: flex-start;
  }
}
