@use 'mixins' as m;
@use 'variables' as v;

.header {
  color: var(--text-color-primary);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.1px;
  z-index: v.$z-3;
  box-shadow: 0 3px 7px 0px var(--header-color-shadow);

  &.hasHash:not(.scrolled) {
    @include m.breakpoint-max(v.$md) {
      .logo {
        opacity: 0;
        transition: all 0.1s;
      }
    }

    @include m.breakpoint(v.$md) {
      &:not(.event):not(.questions) {
        .logo {
          opacity: 0;
          transition: all 0.1s;
        }
      }
    }
  }

  &.sticky {
    @include m.breakpoint-max(v.$md) {
      .logo {
        transform: translateY(-20px);
        transition: all 0.1s ease;
        opacity: 0;
      }
    }

    // on event and questions page, keep logo on desktop
    @include m.breakpoint(v.$md) {
      &:not(.event):not(.questions) {
        .logo {
          transform: translateY(-20px);
          transition: all 0.1s ease;
          opacity: 0;
        }
      }
    }
  }
}

.container {
  height: 100%;
}

.inner {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: center;
  height: calc(v.$header-mobile - 44px);
  margin: 22px 24px;

  @include m.breakpoint(v.$md) {
    height: calc(v.$header-desktop - 44px);
    margin: 22px 40px;
  }

  .sticky & {
    margin-left: auto;
  }
}

.shareBtn,
.themeBtn {
  opacity: 1;

  .hasHash:not(.scrolled) & {
    opacity: 0;
    transition: all 0.1s;
  }
}

.logo {
  transition: all 0.35s;
  margin: 22px 40px;
  width: 140px;
  height: 76px;

  @include m.breakpoint-max(v.$md) {
    margin: 22px 24px;
    width: 100px;
    height: 54px;
  }

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: var(--text-color-primary);
    }
  }

  &.whiteLogo svg {
    path {
      fill: white;
    }
  }
}
