@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  text-align: left;
}

.inner {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.cards {
  @include m.cards($column-desktop: 2, $tablet-bp: 900px);
}

.cardWrapper {
  position: relative;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  --perspective: 1000px;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.card {
  height: 100%;
  --shadow-color: #{v.$peach} !important;

  .description {
    @include t.cta-text;
    font-size: 18px !important;
    * {
      @include t.cta-text;
      font-size: 18px !important;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  strong {
    @include t.headline5;
    margin-top: 32px;
    display: inline-block;
  }
}

.title {
  @include t.subtitle;
  font-weight: 700;
}

.input {
  @include m.button;
  @include t.cta-text-bold;

  color: v.$lead;
  background: var(--text-color-primary);
  margin-bottom: 12px;
  text-align: left;
  width: 100%;
}

.code {
  @include t.cta-text;
}

.loading {
  @include t.headline5;

  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buttons {
  $radius: 8px;
  @include t.cta-text;

  position: relative;
  color: var(--text-color-primary);
  // keep above the section itself, which has zindex of 2
  z-index: 3;
  --menu-background: #{v.$white};
  --menu-item-color: #{v.$blue};
  --menu-item-hover-color: #{v.$white};
  --menu-item-active-color: #{v.$white};
  --card-bg-third-hover: #{v.$blue};
  --card-bg-third: #{v.$indigo};

  svg {
    path {
      fill: currentColor;
    }
  }
}

.faqBtn {
  @include t.cta-text;
  @include m.underline-hover-flash;

  --underline-color: #{v.$white};
  color: v.$white;
  margin-top: 24px;
}

.label {
  opacity: 0;
}
