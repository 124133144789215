@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  position: relative;
}

.header {
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 2/-2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3/-3;
  }
}

.valueList {
  display: flex;
  flex-direction: column;
  gap: 8px 25px;

  @include m.breakpoint(v.$lg) {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
    grid-template-columns: repeat(2, 1fr);
  }
}

.valuesSubmit {
  width: 100%;
  height: 100%;
  &.disabled {
    opacity: 0.66;
    cursor: auto;
    pointer-events: none;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.linksWrapper {
  grid-column: 1/ -1;
  display: flex;
  gap: 20px;
  flex-direction: column;

  @include m.breakpoint(v.$md) {
    grid-column: 2/-2;
  }
  @include m.breakpoint(v.$lg) {
    flex-direction: row;
    grid-column: 3/-2;
  }

  @include m.breakpoint(v.$lg-xl) {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 3/-2;
    flex-direction: row;
  }
}

.cta {
  padding: 1em 2em !important;

  @include m.hover {
    &:hover {
      box-shadow: none !important;
    }
  }
}
.cardWrapper {
  position: relative;
  display: flex;
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-column: 1/ -1;
  grid-template-columns: repeat(1, 1fr);
  @include m.breakpoint(v.$md) {
    margin-top: 48px;
    grid-column: 2/-2;
    grid-template-columns: repeat(2, 1fr);
  }
  @include m.breakpoint(v.$lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 3/-2;
  }
  @include m.breakpoint(v.$lg-xl) {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 3/-2;
  }
}

.buttonWrapper {
  position: relative;
}
.backButtonLg {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, 100%);
  display: none !important;
  @include m.breakpoint(v.$md) {
    display: block !important;
  }
}

.backButton {
  position: relative;
  grid-column: 1 / -1;
  text-align: center;
  margin-top: 10px;

  @include m.breakpoint(v.$md) {
    margin-top: 30px;
  }

  @include m.breakpoint(v.$lg) {
    text-align: left;
  }
}
