@use 'typography' as t;

.description {
  @include t.body;

  a {
    color: var(--text-color-primary);
    text-decoration: underline;
  }
}
