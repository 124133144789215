@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  position: relative;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @include m.breakpoint(v.$tablet) {
    padding: 0;
  }
}

.modal {
  background: v.$white;
  box-shadow: 0px 3px 0px rgba(117, 117, 117, 0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 35px;
  position: relative;
  width: 100%;
  padding: 50px 20px;
  @include m.breakpoint(v.$tablet) {
    height: 467px;
    padding: 124px 79px;
    width: 658px;
  }
}

.logo {
  width: 501px;
}

.button {
  max-width: 263px;
}

.description {
  @include t.subtitle;
  color: v.$lead;
  max-width: 479px;
}

.exit {
  position: absolute;
  top: 0px;
  right: 0px;
  @include m.breakpoint(v.$tablet) {
    top: 10px;
    right: 10px;
  }
}
