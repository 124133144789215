@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.wrapper {
  position: relative;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  > * {
    flex: 1;
  }
}
