@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.header {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}
.wrapper {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;

  @include m.breakpoint(v.$md) {
    display: grid;
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  grid-column: 1 / -1;
  margin-top: -24px;
  text-align: center;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / 7;
    max-width: 400px;
    margin-top: 0;
    text-align: left;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / 7;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @include m.breakpoint(v.$md) {
    max-width: 284px;
  }
  @include m.breakpoint-max(v.$md) {
    margin: auto;
    width: 100%;
  }
}

.description {
  @include t.subtitle;
  color: var(--text-color-primary);
}

.imageWrapper {
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  align-items: center;

  @include m.breakpoint(v.$md) {
    grid-column: 7 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 7 / -3;
  }

  img {
    @include m.indeed-shadow($static: true);
  }
}
