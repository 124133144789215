@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

@mixin events-layout {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}
.cta {
  @include events-layout;
  text-align: center;
  margin: 0;
}
