@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  position: relative;
  height: 450px;
  width: 104px;
  transition: width 0.5s, box-shadow 0.3s;
  overflow: hidden;
  margin-left: 11px;
  margin-right: 11px;
  border-radius: 10px;
  @include m.breakpoint(v.$lg) {
    height: 550px;
  }

  &.active {
    width: 390px;
  }

  @include m.breakpoint-max(v.$lg) {
    width: 310px;

    &.active {
      width: 310px;
    }
  }

  &:not(.active) {
    @include m.hover {
      &:hover {
        box-shadow: 4px 0px 0px 0px var(--card-shadow);
      }
    }
  }
}

.inner {
  width: 100%;
  padding: 28px 20px;
  color: v.$white;
  border-radius: 10px;
  position: relative;
  display: flex;
  text-align: left;
  flex-direction: column;
  height: 450px;
  background: var(--theme-color);
  cursor: pointer;

  @include m.breakpoint(v.$lg) {
    padding: 54px 20px;
  }

  .past:not(.eventsOver) & {
    background: v.$dark-gray;

    a {
      color: v.$dark-gray;
    }
  }

  @include m.breakpoint(v.$lg) {
    height: 550px;
    padding: 54px v.$event-card-pad;
  }
}

.contentWrapper {
  padding: 0 7px;
  position: relative;
  z-index: 5;
}

.hiddenWrapper {
  position: relative;
  z-index: 5;
  height: 100%;

  @include m.breakpoint-max(v.$lg) {
    opacity: 1;
    transform: translateY(0px);
    transition: all ease-in-out 0.35s 0.4s;
  }
}

.top {
  display: flex;
  flex-direction: column;
}

.date {
  @include t.eyebrow;

  white-space: pre-line;
  color: currentColor;
  order: -1;
}

.title {
  @include t.headline3;
  color: currentColor;
  max-width: 555px;
  margin-top: 11px;
}

.verticalTitle {
  @include t.headline3;
  color: currentColor;
  max-width: 555px;
  transform: translate(-50%, 0%) rotate(-180deg);
  white-space: nowrap;
  position: absolute;
  writing-mode: vertical-rl;
  left: 50%;
  margin-top: 0px;
  z-index: 5;
  bottom: 27px;
}

.description {
  @include t.body;
  margin-top: 25px;
  max-width: 281px;
  color: currentColor;

  @include m.breakpoint-max(v.$md) {
    font-size: 16px;
  }
}

.iconWrapper {
  display: flex;
  gap: 10px;
  margin-top: 55px;
  --icon-width: 30px;
  --icon-pad: 0px;

  svg {
    path {
      stroke: var(--theme-color);
    }
  }
}

.cta {
  z-index: 5;
  position: absolute;
  bottom: 43px;
  left: 50%;
  width: 100%;
  bottom: 0;
  transform: translate(-50%, 0px);

  :global(.light) & {
    --background: #{v.$blue};
    --text-color-primary: #{v.$white};
  }
}
.bgImage {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 0%;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
  &.active {
    opacity: 1;
  }
  &.top {
    top: 0%;
  }

  .past:not(.eventsOver) & {
    filter: brightness(0);

    &.active {
      opacity: 0.15;
    }
  }

  @include m.breakpoint-max(v.$lg) {
    opacity: 1;
  }
}
