@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.container {
  pointer-events: none;
  z-index: v.$pageline-z;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.svg {
  max-height: none;
}

.line {
  color: v.$light-grey;
  opacity: 0.5;

  :global(.light) & {
    color: v.$blue;
  }

  &.resizing {
    transition: d 0.25s ease-out, stroke-dasharray 0.25s ease-out,
      stroke-dashoffset 0.25s ease-out;
  }
}

.checkpoint {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: v.$magenta;
  opacity: 0;
  border-radius: 100%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: opacity 0.125s ease-in-out, transform 0.5s ease-in-out;

  &.invisible {
    background: none;
  }

  &.active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
