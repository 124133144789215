@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  text-align: left;
}

.backBtn {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.inner {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -2;
  }
}

.cards {
  @include m.cards;
}

.input {
  @include m.button;
  @include t.cta-text-bold;

  color: v.$lead;
  background: var(--text-color-primary);
  margin-bottom: 12px;
  text-align: left;
  width: 100%;
}

.code {
  @include t.cta-text-bold;
}

.loading {
  @include t.headline5;

  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
