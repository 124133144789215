@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  width: var(--icon-width, 48px);

  &.outlineIcon {
    svg {
      path {
        fill: none !important;
      }
    }
  }
}

.container {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.inner {
  @include m.icon-wrapper;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  button,
  a {
    padding: var(--icon-pad, 1em) !important;
    width: 100%;
  }

  span {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: currentColor;
    }
  }
}
