@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.header {
  margin-bottom: 30px;
  text-align: center;

  @include m.breakpoint(v.$md) {
    grid-column: 3/-3;
    text-align: left;
    margin-bottom: 48px;
  }
}

.eyebrow {
  @include t.eyebrow;
}

.eyebrowInner {
  position: relative;
}

.checkpoint {
  position: absolute;
  top: 50%;
  left: -24px;

  @include m.breakpoint(v.$md) {
    left: -64px;
  }
}

.title {
  @include t.headline2;
  margin-bottom: v.$padding;
  margin-top: 10px;

  @include m.breakpoint(v.$md) {
    margin-right: -1.7em !important;
    max-width: var(--title-max-width, 1180px);
  }

  :global([lang='es']) & {
    @include t.headline2-sm;
  }
}

.description {
  @include t.subtitle;
  max-width: 850px;
}
