@use 'mixins' as m;
@use 'variables' as v;

.container {
  position: relative;
  background: var(--background);
  opacity: 0;
  transition: opacity 0.75s ease, background 0.45s ease;
  overflow-x: hidden;

  &.loaded {
    opacity: 1;
  }

  &.fixedHeader {
    display: flex;
    flex-direction: column;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: max(160vh, 100vw);
  height: max(100vh, 62.5vw);
  transition: opacity 0.3s ease;
  left: max(80vh, 50vw);
  transform: translateX(-50%);

  :global(.light) & {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      var(--background)
    );
  }

  svg {
    -webkit-mask-image: linear-gradient(to top, transparent 5%, black 30%);
    mask-image: linear-gradient(to top, transparent 5%, black 30%);
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;
  }

  @media (max-width: v.$md) and (orientation: portrait) {
    transform: translateX(-75%) scaleX(0.8);
  }
}
