@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.button {
  opacity: 0.8;
  transition: all 0.3s ease;
  width: 10px;
  height: 10px;
  margin: 24px 4px;
  border-radius: 100%;
  background: var(--text-color-primary);

  &.active {
    width: 14px;
    height: 14px;
    opacity: 1;
  }

  @include m.hover {
    &:hover {
      opacity: 1;
    }
  }
}
