@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
	display: flex;
	flex-direction: column;
	gap: 50px;
	position: relative;

	@include m.breakpoint(v.$lg) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;
	}

	@include m.breakpoint(1440px) {
		gap: 128px;
	}
}

.tooltipContainer {
  z-index: 1;
  position: absolute;
  top: 70px;
}

.tooltip {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  background: v.$magenta;
  border-radius: 10px;

  p,
  * {
    @include t.body;
    color: v.$white;
  }

  a {
    text-decoration: underline;
  }
}

.loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: delayedLoad 0.5s ease-in-out;
}

@keyframes delayedLoad {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.paths {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: none;
}

.line {
	display: none;
	color: v.$light-grey;
  opacity: 0.5;
	stroke-dasharray: var(--length);
	stroke-dashoffset: var(--length);
	animation: line 0.25s 0.125s ease-out forwards;

	@include m.breakpoint(v.$lg) {
		display: block;
	}

  :global(.light) & {
    color: v.$blue;
  }
}

@keyframes line {
	0% {
		stroke-dashoffset: var(--length);
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
		marker-end: url(#arrow);
	}
}

.column {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	transition: opacity 0.25s ease-out;

	.container.loading & {
		opacity: 0.5;
	}
}

.columnLabelContainer {
  @include m.button($round: true);
  @include m.box-shadow($static: true);
	--shadow-color: rgba(0, 20, 55, 0.35);
	align-items: center;
	width: 100%;
	height: 56px;
	padding: 16px;
	margin-bottom: 50px;
	background: v.$white;
	color: v.$lead;
}

.columnLabel {
	flex: 1 1 auto;
	color: inherit;
	text-align: left;

	h4 {
		@include t.cta-text-bold;
		display: inline;
		color: inherit;
	}
}

.columnRange {
	@include t.cta-text-bold;
	margin-left: 4px;
	color: v.$grey;
}

.columnIcon {
	flex-shrink: 0;
	margin-right: 4px;
}

.tooltipIcon {
	flex-shrink: 0;
	color: v.$grey;
}

.occupationList {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 16px;
	width: 100%;
	padding-left: 24px;
	transition: opacity 0.5s ease-in-out;

	@include m.breakpoint(1440px) {
		width: 80%;
		padding-left: 0;
	}

	&.loading {
		opacity: 0.75;
	}
}

.occupation {
	@include m.button($round: true);
  @include m.box-shadow($static: false);
	@include t.body;
	align-items: center;
	width: 100%;
	height: 56px;
	padding: 12px 12px 12px 16px;
	background: v.$white;
	color: v.$lead;
	text-align: left;
	font-weight: bold;

	&.selected {
		@include m.box-shadow($static: true);
	}
}

.targetOccupation {
	padding-left: 24px;
}

.expandIcon {
	flex-shrink: 0;
	margin-right: 14px;

	:global(.plus) {
		transition: opacity 0.1s ease-out;

		.occupation.selected & {
			opacity: 0;
		}
	}
}

.emptyContainer,
.columnLoader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100% - 24px);
	min-height: 300px;
	height: 100%;
	margin-left: 24px;
	padding: 16px;
	background: rgba(255, 255, 255, 0.05);
	text-align: center;
	border-radius: 10px;

	@include m.breakpoint(1440px) {
		width: 80%;
		margin-left: 0;
	}
}

.columnLoader > div {
	animation: delayedLoad 0.5s ease-in-out;
}

.emptyIcon {
	margin-bottom: 8px;
}

.emptyMessage {
	@include t.cta-text-bold;
}

.errorContainer {
	grid-column: 1 / -1;
}

.error {
  @include t.subtitle;
  text-align: center;
}