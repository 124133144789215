@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  position: relative;
  overflow: visible;
  --shadow-color: #{v.$indigo};

  :global(.marquee-container) {
    overflow: visible !important;
  }

  :global(.light) & {
    --shadow-color: #{v.$magenta};
  }

  :global(.__react_component_tooltip) {
    @include m.box-shadow;

    border-radius: v.$border-radius;
    background: v.$magenta;
    padding: 24px;
    width: 240px;
    z-index: 4;

    @include m.breakpoint-max(v.$md) {
      width: 200px;
    }

    &:global(.show) {
      opacity: 1 !important;
      pointer-events: none !important;

      @include m.breakpoint-max(v.$md) {
        pointer-events: auto !important;
      }
    }

    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  &.reducedMotion {
    :global(.marquee) {
      justify-content: center;
    }
  }
}

.header {
  grid-column: 1/-1;
  text-align: center;
  max-width: 673px;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;

  * {
    @include t.headline4;
  }
}

.icons {
  margin-top: 120px;

  &.iconsDesktop {
    display: none;

    @include m.breakpoint(v.$md) {
      display: block;
    }
  }

  &.iconsMobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 728px;
    margin: auto;
    padding-right: 12px;
    padding-left: 12px;
    margin-top: 64px;

    @include m.breakpoint(v.$sm) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include m.breakpoint(v.$md) {
      display: none;
    }
  }
}

.marquee {
  @include t.body;

  height: 330px;
  width: 100vw;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-top: 64px;

  @include m.breakpoint-max(1200px) {
    height: 290px;
  }

  &:focus-within > :global(.marquee) {
    animation-play-state: paused;
  }
}
