@use 'variables' as v;
@use 'mixins' as m;

.mktoFieldWrap {
  position: relative;
}

/* checkbox */
.mktoLogicalField {
  padding: 0;
  &.mktoCheckboxList {
    position: absolute;
    right: 0;
    top: 0;

    /* longer label to expand click zone */
    label {
      width: 40px;
      height: 40px;
      position: relative;
      z-index: 2;
      margin: 0 !important;

      &:hover {
        cursor: pointer;
      }
    }
  }

  /* hide original checkbox */
  input[type='checkbox'] {
    opacity: 0 !important;
    position: relative;
    z-index: 2;
  }

  /* fake checkbox */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50% !important;
    box-shadow: 0px 0px 0px 1px v.$grey;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: v.$blue;
    transform: translate(-50%, -50%);
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    border-radius: 50% !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  /* update fake checkbox when selected */
  &.mktoValid::before {
    background: #003a9b;
  }
}

/* custom radio buttons */
.mktoRadioList {
  &:hover {
    cursor: pointer;
  }

  input {
    width: 35px !important;
    height: 35px !important;
    accent-color: #003a9b;

    &:hover {
      cursor: pointer;
    }
  }

  label {
    font-family: 'Indeed sans' !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 35px !important;
    margin-left: 48px !important;

    &:hover {
      cursor: pointer;
    }
  }
}

/* submit button */
div.mktoButtonRow > span > button {
  height: 60px !important;
  width: 230px !important;

  --shadow-color: transparent;
}

form .mktoFormRow:nth-of-type(12) {
  margin-top: 0 !important;
}
