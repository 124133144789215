@use 'sass:math';
@use 'variables' as v;

// Converts pixels to rem based on base font size of 16px
@function rem($target, $base-size: 16px) {
  @return math.div($target, $base-size) * 1rem;
}

@mixin breakpoint($minimum) {
  @media (min-width: $minimum) {
    @content;
  }
}
@mixin breakpoint-max($maximum) {
  $max-adjust: $maximum - 1;
  @media (max-width: $max-adjust) {
    @content;
  }
}

@mixin zoom-breakpoint {
  @media (max-height: 500px) {
    @content;
  }
}

@mixin reduced-motion($reduce: reduce) {
  @media (prefers-reduced-motion: $reduce) {
    @content;
  }
}

@mixin font(
  $font-family: v.$font-family,
  $font-size: (
    sm: 16px,
    md: 16px,
    lg: 16px,
  ),
  $letter-spacing: (
    sm: 0,
    md: 0,
    lg: 0,
  ),
  $line-height: (
    sm: 1.2,
    md: 1.2,
    lg: 1.2,
  )
) {
  font-family: $font-family;
  font-size: map-get($font-size, sm);
  letter-spacing: map-get($letter-spacing, sm);
  line-height: map-get($line-height, sm);
  color: var(--text-color-primary);

  @each $key, $breakpoint in v.$breakpoints {
    @include breakpoint($breakpoint) {
      font-size: map-get($font-size, $key);
      letter-spacing: map-get($letter-spacing, $key);
      line-height: map-get($line-height, $key);
    }
  }
}

// Centered container with a max-width and gutters on both sides
@mixin container {
  margin-left: auto;
  margin-right: auto;
  max-width: v.$max-width;
  padding-left: v.$gutter;
  padding-right: v.$gutter;

  @include breakpoint(v.$sm) {
    max-width: v.$max-width;
    padding-left: v.$gutter-md;
    padding-right: v.$gutter-md;
  }
}

@mixin hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin slice {
  padding-bottom: v.$slice-pad;
  padding-top: v.$slice-pad;

  @include breakpoint(v.$md) {
    padding-bottom: v.$slice-pad-md;
    padding-top: v.$slice-pad-md;
  }
}

@mixin remove-list-formatting {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin button-reset {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin button($round: false) {
  border-radius: 10px;
  display: inline-flex;
  text-align: center;
  text-decoration: none;

  @if $round {
    padding: 8px;
  } @else {
    padding: 1em;
  }
}

@mixin fullscreen {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

@mixin page {
  height: 100%;
  width: 100%;
}

@mixin cards(
  $column-desktop: 3,
  $column-tablet: 2,
  $desktop-bp: v.$lg,
  $tablet-bp: v.$md,
  $gap: 20px
) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint($tablet-bp) {
    grid-template-columns: repeat($column-tablet, 1fr);
  }

  @include breakpoint($desktop-bp) {
    grid-template-columns: repeat($column-desktop, 1fr);
  }
}

@mixin icon-wrapper($bg: transparent, $border-color: transparent) {
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: $bg;
  outline: 1px solid $border-color;
}

@mixin indeed-shadow($static: false) {
  border-bottom: 3px solid transparent;

  @if $static {
    border-bottom: 3px solid var(--shadow-color);
  } @else {
    @include m.hover {
      &:hover {
        border-bottom: 3px solid var(--shadow-color);
      }
    }
  }
}

@mixin box-shadow($static: true) {
  @if $static {
    box-shadow: 0px 4px 0px -1px var(--shadow-color, #{v.$indigo});
  } @else {
    @include hover {
      &:hover {
        box-shadow: 0px 4px 0px -1px var(--shadow-color, #{v.$indigo});
      }
    }
  }
}

@mixin paragraph {
  letter-spacing: 0.04em;
  margin-bottom: 1.35em;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: v.$blue;
    text-decoration: underline;
  }
}

@mixin richtext {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    margin-bottom: 1em;
    letter-spacing: 0.04em;
  }

  p {
    @include paragraph;

    margin-bottom: 1.35em;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: v.$blue;
      text-decoration: underline;
    }
  }

  li {
    margin: 1em 0;
    padding-left: 24px;
    position: relative;
    line-height: 1.5 !important;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0.75em;
      margin-right: 12px;
      width: 0.3em;
      height: 0.3em;
      border-radius: 100%;
      background: currentColor;
    }

    strong,
    b {
      font-weight: 700;
    }
  }

  a {
    color: var(--cta-color, v.$blue);
    word-break: break-word;

    strong,
    b {
      font-weight: 700;
    }
  }
}

@mixin absPosition($top: 0, $bottom: 0, $left: 0, $right: 0) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin underline-hover-grow {
  position: relative;
  background-image: linear-gradient(
    var(--underline-color),
    var(--underline-color)
  );
  background-size: 0 1.5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.35s ease;

  @include hover {
    &:hover {
      background-size: 100% 1.5px;
    }
  }
}

@mixin underline-hover-flash {
  position: relative;
  background-image: linear-gradient(
    var(--underline-color),
    var(--underline-color)
  );
  background-size: 100% 1.5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.35s ease;

  @keyframes flash {
    0% {
      background-size: 100% 1.5px;
      background-position: 0% 100%;
    }

    40% {
      background-size: 0% 1.5px;
      background-position: 0% 100%;
    }

    42% {
      background-size: 0% 1.5px;
      background-position: 100% 100%;
    }

    100% {
      background-size: 100% 1.5px;
      background-position: 100% 100%;
    }
  }

  @include hover {
    &:hover {
      animation: flash 0.5s ease;
    }
  }
}

@mixin browser-focus-outline($important: false) {
  @if $important {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: 5px !important;
  } @else {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 5px;
  }
}
