// You may ultimately want to replace this file
// with multiple files for each type style.
// The below is just an example of how you could
// create a mixin called `title` that utilizes
// the font mixin

@use "mixins" as m;
@use 'variables' as v;

@mixin headline1 {
  @include m.font(
    $font-size: (
      sm: 42px,
      md: 64px,
      lg: min(calc(64px + 0.076 * (100vw - 1028px)), 96px),
    ),
    $line-height: (
      sm: 1.05,
      md: 1.05,
      lg: 1.05,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
  font-weight: 800;
}

@mixin headline2 {
  @include m.font(
    $font-size: (
      sm: 42px,
      md: 54px,
      lg: 72px,
    ),
    $line-height: (
      sm: 1.12,
      md: 1.12,
      lg: 1.12,
    ),
    $letter-spacing: (
      sm: m.rem(1px),
      md: m.rem(1px),
      lg: m.rem(1px),
    )
  );
  font-weight: 800;
}

@mixin headline2-sm {
  @include m.font(
    $font-size: (
      sm: 40px,
      md: 50px,
      lg: 68px,
    ),
    $line-height: (
      sm: 1.12,
      md: 1.12,
      lg: 1.12,
    ),
    $letter-spacing: (
      sm: m.rem(1px),
      md: m.rem(1px),
      lg: m.rem(1px),
    )
  );
  font-weight: 800;
}

@mixin headline3 {
  @include m.font(
    $font-size: (
      sm: 40px,
      md: 42px,
      lg: 48px,
    ),
    $line-height: (
      sm: 1.15,
      md: 1.15,
      lg: 1.15,
    ),
    $letter-spacing: (
      sm: m.rem(1px),
      md: m.rem(1px),
      lg: m.rem(1px),
    )
  );
  font-weight: 800;
}

@mixin headline4 {
  @include m.font(
    $font-size: (
      sm: 28px,
      md: 30px,
      lg: 40px,
    ),
    $line-height: (
      sm: 1.2,
      md: 1.2,
      lg: 1.2,
    ),
    $letter-spacing: (
      sm: regular,
      md: regular,
      lg: regular,
    )
  );
  font-weight: 700;
}

@mixin menuItem($sm: 20px, $md: 24px, $lg: 28px) {
  @include m.font(
    $font-size: (
      sm: $sm,
      md: $md,
      lg: $lg,
    ),
    $line-height: (
      sm: 1.2,
      md: 1.12,
      lg: 1.12,
    ),
    $letter-spacing: (
      sm: regular,
      md: regular,
      lg: regular,
    )
  );
}

@mixin headline5($sm: 20px, $md: 20px, $lg: 22px) {
  @include m.font(
    $font-size: (
      sm: $sm,
      md: $md,
      lg: $lg,
    ),
    $line-height: (
      sm: 1.2,
      md: 1.2,
      lg: 1.2,
    ),
    $letter-spacing: (
      sm: regular,
      md: regular,
      lg: regular,
    )
  );
  font-weight: 700;
}

@mixin subtitle($sm: 16px, $md: 18px, $lg: 20px) {
  @include m.font(
    $font-size: (
      sm: $sm,
      md: $md,
      lg: $lg,
    ),
    $line-height: (
      sm: 1.6,
      md: 1.6,
      lg: 1.6,
    ),
    $letter-spacing: (
      sm: 0.015em,
      md: 0.015em,
      lg: 0.015em,
    )
  );
}

@mixin body-lg {
  @include m.font(
    $font-family: v.$font-body,
    $font-size: (
      sm: 18px,
      md: 18px,
      lg: 18px,
    ),
    $line-height: (
      sm: 1.4,
      md: 1.4,
      lg: 1.4,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
}

@mixin body {
  @include m.font(
    $font-family: v.$font-body,
    $font-size: (
      sm: 14px,
      md: 14px,
      lg: 14px,
    ),
    $line-height: (
      sm: 1.6,
      md: 1.6,
      lg: 1.6,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
}

@mixin eyebrow {
  @include m.font(
    $font-family: v.$font-body,
    $font-size: (
      sm: 16px,
      md: 16px,
      lg: 14px,
    ),
    $line-height: (
      sm: 1,
      md: 1,
      lg: 1.4,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
  font-weight: 700;
}

@mixin cta-text {
  @include m.font(
    $font-family: v.$font-body,
    $font-size: (
      sm: 16px,
      md: 16px,
      lg: 16px,
    ),
    $line-height: (
      sm: 1.4,
      md: 1.4,
      lg: 1.4,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
}

@mixin faq-body {
  @include m.font(
    $font-family: v.$font-body,
    $font-size: (
      sm: 16px,
      md: 16px,
      lg: 16px,
    ),
    $line-height: (
      sm: 1.7,
      md: 1.7,
      lg: 1.7,
    ),
    $letter-spacing: (
      sm: normal,
      md: normal,
      lg: normal,
    )
  );
}

@mixin cta-text-bold {
  @include cta-text;
  font-weight: bold;
  color: var(--text-color-primary);
}
