@use 'mixins' as m;
@use 'variables' as v;

.grid {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(4, 1fr);

  @include m.breakpoint(v.$md) {
    grid-template-columns: repeat(12, 1fr);
  }

  @include m.breakpoint(v.$lg) {
    grid-row-gap: 0px;
  }
}
