@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.title {
  @include t.headline3;
  margin: 0.4em 0 0.25em;
  padding-top: 48px;
}

.formWrapper {
  width: 100%;
  display: flex;
  margin: 0 0 80px;
}

.message {
  margin-top: 48px;
  margin-bottom: 48px;
  pointer-events: none;

  * {
    @include t.menuItem;
    line-height: 1.5;
  }
}
