@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.resourceCTAs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  @include m.breakpoint(v.$sm) {
    flex-direction: row;
  }
  @include m.breakpoint(v.$md) {
    margin-bottom: 48px;
  }
}

.resourceCTA {
  padding: 1em 1em !important;

  @include m.breakpoint(v.$md) {
    align-self: flex-start;
  }
}
