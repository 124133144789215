@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

$radius: 8px;

.wrapper {
  @include t.cta-text;

  position: relative;
  color: var(--text-color-primary);
  display: flex;
  margin: auto;
  justify-content: center;
  // keep above page line dot
  z-index: 1;
  --menu-background: var(--background);
  --menu-item-color: var(--text-color-primary);
  --menu-item-hover-color: var(--text-color-primary);
  --menu-item-active-color: var(--text-color-primary);
  --dropdown-width: 340px;

  svg {
    path {
      fill: currentColor;
    }
  }
}

.item {
  position: relative;
  min-width: 320px;
}
