@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  padding-bottom: 8px;
  grid-row-gap: 0 !important;
}

.time {
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 1/5;
    padding-right: 24px;
  }
}

.title {
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 5/-1;
  }
}
