@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.image {
  span,
  img {
    display: block !important;
  }

  &.round {
    img {
      border-radius: 10px;
    }
  }

  &.fill {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    img {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      width: 0;
      height: 0;
      object-fit: var(--image-fit, cover);
      padding: 0;
      border: none;
      margin: auto;
      object-position: var(--image-position, center center);
    }
  }

  &.responsive {
    position: relative;
    width: 100%;
    padding-top: var(--pt);
    height: 0;

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      align-items: center;
      margin: auto;
      display: flex !important;

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: var(--image-fit, cover);
        object-position: var(--image-position, center center);
      }
    }
  }
}
