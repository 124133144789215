@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.button {
  @include t.body;

  --icon-width: 74px;
  position: relative;
  margin-bottom: 80px;
  margin-right: 0;
  margin-left: auto;
  padding-bottom: 80px;

  @include m.breakpoint-max(v.$md) {
    padding-bottom: 48px;
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: auto;
  }

  svg {
    transform: rotate(-90deg);
  }

  button {
    position: relative;
    z-index: v.$z-1;
  }
}
