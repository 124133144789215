@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  @include m.breakpoint(v.$md) {
    @include m.container;
  }

  @include m.breakpoint-max(v.$md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .section.resources & {
    padding-left: 20px !important;

    @include m.breakpoint(v.$md) {
      padding: 0 !important;
    }
  }
}

.header {
  :global([lang='en']) & {
    --title-max-width: 800px;
  }

  .section.resources & {
    @include m.breakpoint(v.$md) {
      padding: 0 !important;
    }
  }
}

.inner {
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 2/-2;
  }

  @include m.breakpoint-max(v.$md) {
    width: 100%;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3/-3;
  }

  &.cards {
    @include m.breakpoint(v.$lg-xl) {
      grid-column: 3/-2;
    }
  }

  .resources & {
    grid-column: 1/-1;
  }
}

.embla {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.viewport {
  overflow: hidden;
  width: 100%;
  cursor: grab;

  &:global(.is-draggable) {
    cursor: move;
    cursor: grab;
  }

  &:global(.is-dragging) {
    cursor: grabbing;
  }

  .container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -20px;
    align-items: center;
    min-height: 300px;

    .eventGallery & {
      min-height: 100px;
    }

    &.resizing {
      transition: transform 0.25s ease-out;
    }

    .partners & {
      min-height: 342px;
    }
  }
}

.cta {
  text-align: center;
  margin-top: 48px;
}

.nextBtn {
  @include m.breakpoint(v.$md) {
    display: none;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 66px;
}
