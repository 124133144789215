@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.footer {
  z-index: v.$footer-z;
  position: relative;
  background-color: v.$lighter-gray;
  color: white;
  padding: 41px 0;
  color: var(--footer-color-primary);

  :global(.light) & {
    background-color: v.$white;
  }
}

.checkpoint {
  left: 50%;
}

.container {
  display: flex;
  flex-direction: column;
}

.topRow {
  margin-bottom: 24px;

  @include m.breakpoint(800px) {
    margin-bottom: 91px;
  }
}

.logoWrapper {
  grid-column: 1 / -1;

  @include m.breakpoint(800px) {
    grid-column: 2 / span 2;
  }

  .logo {
    width: 120px;
    display: inline-flex;
    margin-left: -4px;

    svg {
      width: 100%;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
}
.listTitle {
  @include t.cta-text;
  font-family: v.$font-family;
  color: var(--footer-color-primary);
  font-weight: bold;
  margin-bottom: 14px;
}

.exploreList {
  grid-column: 1 / -1;
  margin-bottom: 24px;

  @include m.breakpoint(800px) {
    grid-column: 2 / span 4;
    margin-bottom: 0;
  }

  @include m.breakpoint(1200px) {
    grid-column: 5 / span 3;
    margin-bottom: 0;
  }
}

.resourceList {
  grid-column: 1 / -1;
  margin-bottom: 24px;

  @include m.breakpoint(800px) {
    grid-column: 6 / span 3;
    margin-bottom: 0;
  }

  @include m.breakpoint(1200px) {
    grid-column: 8 / span 2;
    margin-bottom: 0;
  }
}
.socialList {
  grid-column: 1 / -1;
  margin-bottom: 24px;

  @include m.breakpoint(800px) {
    grid-column: 9 / -2;
    margin-bottom: 0;
  }

  @include m.breakpoint(1200px) {
    grid-column: 10 / span 2;
    margin-bottom: 0;
  }
}

.iconWrapper {
  display: flex;
  // gap: 30px;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;

  a {
    margin-left: 15px;
    margin-right: 15px;
    @include m.hover {
      &:hover {
        svg {
          path {
            fill: v.$blue;
          }
        }
      }
    }
  }
}
.link {
  a {
    @include t.headline5;
    color: var(--footer-color-primary);
    font-weight: normal !important;
    line-height: 1.58 !important;
    letter-spacing: 0.02em !important;
    transition: opacity 0.3s ease;

    @include m.hover {
      &:hover {
        color: v.$blue;
      }
    }
  }
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.infoTextLeft {
  @include t.body;
  grid-column: 1 / -1;

  @include m.breakpoint(800px) {
    grid-column: 2 / span 4;
  }
  color: v.$dark-gray;
  a {
    color: v.$dark-gray;
    text-decoration: underline;
    margin-right: 8px;
  }
}

.infoTextRight {
  @include t.body;
  color: v.$dark-gray;
  grid-column: 1 / -1;

  @include m.breakpoint(800px) {
    grid-column: 8 / span 5;
  }
}

.locale {
  display: flex;
  margin-top: 24px;
}

// .localeBtn {
//   &.active {
//     color: v.$blue;
//   }

//   &:first-of-type {
//     padding-right: 12px;
//     border-right: 1px solid v.$lead;
//   }

//   &:last-of-type {
//     padding-left: 12px;
//   }
// }

.localeDropdown {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 200px;

  :global(.dark) & {
    --outline-secondary: #{v.$lighter-gray};
  }

  button {
    outline: none;
    border: none;
    background: v.$white;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-bottom: 0.5em;
    --text-color-primary: #{v.$blue};
    border: 1px solid var(--outline-secondary);

    * {
      text-align: left;
    }

    svg {
      path {
        fill: v.$blue;
      }
    }
  }

  ul {
    --shadow-color: transparent;
    --card-bg-third-hover: #{v.$light-grey};
    --card-bg-third: #{v.$blue};
    --menu-item-hover-color: #{v.$blue};
    --menu-item-active-color: #{v.$white};
    background: v.$white;
    outline: none;
    border: 1px solid var(--outline-secondary);
  }
}
