@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

$mobile-icon-size: 100px;

.item {
  @include m.box-shadow;
  margin-left: 8px;
  margin-right: 8px;

  position: relative;
  --icon-pad: 12px;
  --image-fit: contain;
  --image-position: center center;
  --text-color-primary: #{v.$white};
  --icon-width: 130px;
  border-radius: 100%;

  @include m.reduced-motion(no-preference) {
    animation: blink infinite 3s var(--delay, 0.5s) ease-in-out;
  }

  @include m.reduced-motion(reduce) {
    animation: blink-reduced infinite 6s var(--delay, 0.5s) ease-in-out;
  }

  @keyframes blink {
    0% {
      transform: scale(1) translateY(0);
    }

    40% {
      transform: scale(1.04) translateY(6px);
    }

    100% {
      transform: scale(1) translateY(0);
    }
  }

  @keyframes blink-reduced {
    0% {
      transform: scale(1) translateY(0);
    }

    40% {
      transform: scale(1.02) translateY(3px);
    }

    100% {
      transform: scale(1) translateY(0);
    }
  }

  img {
    max-height: var(--max-height, 48px) !important;
    max-width: var(--max-width, 120px) !important;
    margin: auto;
  }

  @include m.breakpoint-max(1200px) {
    width: 100px;
    height: 100px;
    --icon-width: 100px;
  }

  &.marquee {
    @include m.breakpoint-max(1200px) {
      margin-left: -4px;
      margin-right: -4px;
    }

    @include m.breakpoint-max(900px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &.grid {
    // reset to not use the top, bottom value comes from json
    margin-bottom: 8px !important;
    margin-top: 8px !important;

    @include m.breakpoint-max(628px) {
      width: max(20vw, 100px);
      height: max(20vw, 100px);
      --icon-width: max(20vw, 100px);
      margin-left: max(2vw, 8px);
      margin-right: max(2vw, 8px);

      // offset every third item
      &:nth-child(3n + 3) {
        margin-bottom: 20px !important;
      }
    }

    @include m.breakpoint-max(v.$sm) {
      width: 25vw;
      height: 25vw;
      --icon-width: 25vw;
      margin-left: max(2vw, 8px);
      margin-right: max(2vw, 8px);
    }
  }
}

.button {
  &:focus-visible {
    border: 4px solid v.$magenta;
  }

  button,
  a {
    position: relative;
    display: inline-block;
  }
}

.tooltipLink {
  @include t.cta-text-bold;
  text-decoration: underline;
  display: inline-block;
  margin-top: 24px;
  color: v.$white;
}
