@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.resourceSlide {
  position: relative;
  padding-left: 20px;
  flex: 0 0 80%;
  align-self: var(--card-alignment, stretch);

  &:first-of-type {
    @include m.breakpoint(v.$sm) {
      padding-left: 40px;
    }

    @include m.breakpoint(v.$md) {
      padding-left: 20px;
    }
  }

  @include m.breakpoint(v.$sm) {
    flex: 0 0 320px;
  }

  @include m.breakpoint(v.$md) {
    flex: 0 0 50%;
  }
}
