@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: v.$z-1;

  div[data-component='collapsible'] {
    opacity: 0;
    transform: translateY(8px);
    transition: all 0.35s ease,
      opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) var(--delay, 0.25s),
      transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) var(--delay, 0.25s);

    @include m.reduced-motion(reduce) {
      transition: all 0.35s ease,
        opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s,
        transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:global(.ReactModal__Overlay--after-open) {
    div[data-component='collapsible'] {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.content {
  background-color: var(--background);
  height: 100vh;
  width: 100%;
  overflow: auto;
  position: absolute;
  padding: 64px 0px;
}

.close {
  box-sizing: content-box;
  cursor: pointer;
  padding: 25px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: v.$z-1;

  svg {
    transform: rotate(0deg);
    transition: transform 0.35s;
  }

  @include m.breakpoint(v.$lg) {
    top: 75px;
    right: 100px;
  }

  @include m.reduced-motion(no-preference) {
    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  @include m.reduced-motion(reduce) {
    &:hover {
      opacity: 0.9;
    }
  }
}
