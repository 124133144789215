@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper,
.list {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: -10px;
  width: 100%;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.list {
  // offset the grid gap
  margin-top: -20px;
}

.active,
.inactive {
  @include m.box-shadow($static: false);
  --shadow-color: #{v.$light-yellow};
}

.inactive {
  filter: brightness(0.9);

  :global(.light) & {
    filter: brightness(0.98);
  }
}
