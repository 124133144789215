@use 'mixins' as m;

.container {
  position: relative;
  width: 100%;

  &:not(.fullbleed) {
    @include m.container;
  }
}
