@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.container {
  position: fixed;
  top: calc(100% - 60px);
  left: 16px;
  z-index: 100;

  :global(.__react_component_tooltip) {
    @include m.box-shadow;

    border-radius: v.$border-radius;
    background: v.$magenta;
    padding: 14px 20px;
    width: 220px;
    z-index: 4;

    &:global(.show) {
      opacity: 1 !important;
      pointer-events: none !important;
    }

    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  button {
    @include t.body;
    @include m.button;

    background: var(--background);
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  &.intro {
    button {
      background: v.$blue;
      color: v.$white;
    }

    @include m.breakpoint-max(v.$sm) {
      top: calc(100% - 64px);

      button {
        padding: 16px 12px !important;
      }
    }
  }

  &.home {
    button {
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    }
  }

  @include m.breakpoint(v.$md) {
    left: 40px;
  }
}
