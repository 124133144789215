@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

:global(.slice) {
  z-index: v.$slice-z;
  position: relative;
  margin-bottom: calc(#{v.$slice-pad-lg} - 48px);
  margin-top: 48px;
  padding-bottom: 48px;

  &:not(.first) {
    margin-top: calc(#{v.$slice-pad-lg} - 48px);
    padding-top: 48px;
  }

  &.first {
    padding-top: 96px;
    margin-top: -48px;

    @include m.breakpoint(v.$md) {
      padding-top: 96px;
      margin-top: -48px;
    }
  }

  &.slice-see-more {
    padding: 0;
    margin: 0;
  }
}
