@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

:global(.toast) {
  @include m.button;
  @include t.cta-text-bold;
  text-decoration: none !important;
  text-align: center !important;
  background: var(--text-color-primary) !important;
  z-index: 3;
  width: 320px;
  color: var(--background) !important;
}
