@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

$radius: 8px;

.menu {
  @include t.cta-text;
  @include m.box-shadow;

  position: absolute;
  border: 1px solid currentColor;
  width: 100%;
  text-align: left;
  border-radius: $radius;
  color: var(--menu-item-color);
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--menu-background);
}
