@use 'mixins' as m;
@use 'variables' as v;
@use 'typography' as t;

.container {
  width: 110px;
  height: 60px;
  padding: 6px;
  border-radius: 48px;
  border: 1px solid var(--outline-secondary);
  position: relative;
  justify-content: space-between;
  margin: auto 24px;

  &.hamburger {
    display: block;
    margin: 24px 0;
    border: 1px solid var(--iframe-bg);

    :global(.dark) & {
      border: 1px solid v.$blue;
    }

    @include m.breakpoint(v.$md) {
      display: none;
    }
  }

  &.header {
    display: none;

    @include m.breakpoint(v.$md) {
      display: block;
    }
  }

  &.event {
    display: none;
  }
}

.circle {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background: var(--text-color-primary);
  position: absolute;

  .hamburger & {
    background: var(--background);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  border-radius: 40px;
}

.icon {
  position: relative;
  z-index: 1;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --icon-color: var(--text-color-primary);

  .hamburger & {
    --icon-color: var(--background);
  }

  &.active {
    --icon-color: var(--background);

    .hamburger & {
      --icon-color: var(--text-color-primary);
    }
  }

  svg {
    path {
      stroke: var(--icon-color);
    }
  }
}
