@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.slide {
  position: relative;
  padding-left: 20px;
  flex: 0 0 80%;
  transition: all 0.3s ease, opacity 0.5s ease;
  padding-bottom: 24px;

  &:not(.selected) {
    @include m.breakpoint-max(v.$md) {
      opacity: 0.6;
      filter: blur(2px);
    }
  }

  &.selected {
    @include m.breakpoint-max(v.$md) {
      opacity: 1;
      filter: blur(0px);
    }
  }

  @include m.breakpoint(v.$md) {
    flex: 0 0 100%;
  }
}

.image {
  position: relative;
  // set all images to be the same aspect ratio
  padding-top: 65%;
}

.subtitle {
  @include t.body;

  position: absolute;
  bottom: 0;
  transform: translateY(110%);
}
