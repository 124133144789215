@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  position: relative;
  z-index: 2;

  @include m.breakpoint(v.$md) {
    min-width: var(--dropdown-width, 0px);
  }
}

.button {
  @include m.button;

  transition: all 0.3s ease;
  border: 1px solid currentColor;
  position: relative;
  width: 100%;
  align-items: center;
  padding-left: 0.8em;
  transition: background 0.3s ease;
  padding-right: 2em;

  * {
    transition: all 0.3s ease;
  }

  @include m.breakpoint(v.$sm) {
    padding-left: 2em;
    padding-right: 3em;
  }

  @include m.hover {
    &:hover {
      cursor: pointer;
      background: white !important;
      color: v.$blue !important;

      * {
        color: v.$blue !important;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.focusVisible:focus-visible {
    outline: 1px solid currentColor;
    outline-offset: 3px;
  }
}

.prefix {
  @include t.cta-text-bold;

  margin-right: 12px;
  text-align: left;
}

.text {
  @include t.cta-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.icon {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 8px;
  transform: rotate(0deg);

  .menuOpen & {
    transform: rotate(180deg);
  }
}
