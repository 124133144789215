@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.button {
  outline: 0;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: auto;
  transition: all 0.3s ease;
  --icon-width: 74px;

  @include m.breakpoint-max(v.$md) {
    display: none;
  }

  @include m.breakpoint-max(v.$lg) {
    --icon-width: 48px;
  }

  &.prev {
    @include m.breakpoint(v.$md) {
      left: 24px;
    }

    @include m.breakpoint(v.$lg) {
      left: 10%;

      @include m.hover {
        &:hover {
          left: calc(10% - 5px);
        }
      }

      &:focus-within {
        left: calc(10% - 5px);
      }
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &.next {
    @include m.breakpoint(v.$md) {
      right: 24px;
    }

    @include m.breakpoint(v.$lg) {
      right: 10%;

      @include m.hover {
        &:hover {
          right: calc(10% - 5px);
        }

        &:focus-within {
          right: calc(10% - 5px);
        }
      }
    }
  }

  &.eventGallery {
    // offset the navigation height
    margin-top: -33px;
  }

  &.resources {
    --offset: -8%;

    @include m.breakpoint(v.$lg) {
      --offset: -15%;
    }

    @include m.breakpoint(v.$lg-xl) {
      --offset: -9%;
    }

    &.prev {
      left: var(--offset);

      @include m.hover {
        &:hover {
          left: calc(var(--offset) - 5px);
        }
      }
      &:focus-within {
        left: calc(var(--offset) - 5px);
      }
    }

    &.next {
      right: var(--offset);

      @include m.hover {
        &:hover {
          right: calc(var(--offset) - 5px);
        }
      }
      &:focus-within {
        right: calc(var(--offset) - 5px);
      }
    }
  }

  &.disabled {
    opacity: 0;
  }
}
