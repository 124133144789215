@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 73, 194, 1) 100%
  );
  z-index: 99;
  overflow: hidden;
  background-position-y: 0%;
  animation: fade 8s;

  &.interrupted {
    opacity: 0;
    animation: none;
  }
}

.line {
  position: absolute;
  max-width: none;
  width: 450%;
  overflow: visible;

  @include m.breakpoint(v.$sm) {
    width: 300%;
  }
}
.image {
  height: auto;
  transform: scale(0) translate(-50%, -50%);
  display: block;
  position: absolute;
  max-width: 100%;
  opacity: 0;
  z-index: 5;
}

.image1 {
  top: 10%;
  left: 5%;
  @include m.breakpoint(v.$sm) {
    left: 0%;
    top: 20%;
  }

  @include m.breakpoint(v.$md) {
    left: 0%;
    top: 20%;
  }
  @include m.breakpoint(v.$laptop) {
    left: 35%;
    top: 30%;
  }
  @include m.zoom-breakpoint {
    top: -10%;
  }
}

.image2 {
  top: 30%;
  z-index: 10;
  left: 20%;
  @include m.breakpoint(v.$sm) {
    left: 48%;
    top: 25%;
  }
  @include m.breakpoint(v.$laptop) {
    left: 55%;
    top: 45%;
  }
  @include m.zoom-breakpoint {
    top: -5%;
  }
}
.image3 {
  top: 0%;
  left: 5%;
  @include m.breakpoint(v.$md) {
    left: 5%;
    top: 5%;
  }
  @include m.breakpoint(v.$laptop) {
    left: 15%;
    top: 33%;
  }
  @include m.zoom-breakpoint {
    top: -10%;
    left: 0%;
  }
}
.image4 {
  top: 20%;
  left: 25%;
  @include m.breakpoint(v.$md) {
    left: 35%;
    top: 0%;
  }
  @include m.breakpoint(v.$laptop) {
    left: 55%;
    top: 30%;
  }
  @include m.zoom-breakpoint {
    top: 0%;
    left: 40%;
  }
}
.image5 {
  top: 2%;
  left: 2%;

  @include m.zoom-breakpoint {
    top: -16% !important;
  }

  @include m.breakpoint(v.$md) {
    top: 0%;
    left: 5%;
  }
  @include m.breakpoint(v.$laptop) {
    top: 10%;
    left: 10%;
  }
}
.circle {
  // transform: translateY(-8px);
  // offset-path: path(
  //   'M1 598C96 525.5 351.296 372.682 639.5 421C893 463.5 924 606.5 1146.5 646.5C1369 686.5 1555.38 597.56 1659.5 545.5C1786.5 482 2094.86 168.581 2371 327C2591.5 453.5 2744.5 338 2829.5 1'
  // );
  // position: relative;
  z-index: 10000;
}

.text {
  @include t.headline4;
  max-width: 350px;
  position: absolute;
  color: v.$white;
  opacity: 0;
  z-index: 10;
  font-size: 26px;
  @include m.breakpoint(v.$sm) {
    max-width: 575px;
    color: v.$white;
  }

  :global([lang='es']) & {
    @include t.headline5($sm: 22px, $md: 26px, $lg: 32px);

    @include m.zoom-breakpoint {
      @include t.subtitle;
      line-height: 1 !important;
    }
  }
}

.text1 {
  color: v.$blue;
  top: 75%;
  left: 20px;
  @include m.breakpoint(v.$sm) {
    left: 20%;
  }
}

.text2 {
  left: 20px;
  top: 65%;
  @include m.breakpoint(v.$sm) {
    top: 80%;
  }
  @include m.breakpoint(v.$md) {
    top: 65%;
    left: 20%;
  }
  @include m.zoom-breakpoint {
    top: 75%;
  }
}

.text3 {
  top: 45%;
  left: 20px;
  @include m.breakpoint(v.$sm) {
    left: 10%;
  }

  @include m.zoom-breakpoint {
    top: 46% !important;
  }
}

.elipse1 {
  top: -20%;
  left: 10%;
  z-index: 1;
  display: none;
  @include m.breakpoint(v.$md) {
    display: inline;
  }
}

.elipse2 {
  top: 60%;
  left: 20%;
  transform: scale(0.7) rotate(180deg);
  z-index: 0;
  display: none;
  @include m.breakpoint(v.$laptop) {
    display: inline;
  }
}

.skipButton {
  padding: 14px 20px;
  z-index: 100000;
  white-space: nowrap;
  // reduce width to give more room to transcript button on mobile
  @include m.breakpoint-max(v.$sm) {
    padding: 1em 1em !important;
  }
}

.transcriptButton {
  --background: #{v.$white};
  --text-color-primary: #{v.$blue};
  --shadow-color: transparent;
}

.playPauseButton {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  border: 1px solid v.$blue;
  z-index: 8888;
  width: 50px;
  height: 50px;
  background: v.$white;
  transition: box-shadow 0.25s;
  &:hover {
    @include m.box-shadow;
  }
  svg {
    z-index: 8888;
    height: 24px !important;
    width: 24px !important;
    max-width: none !important;
    max-height: none !important;
  }
}

.play {
  svg {
    transform: translateX(2px);
  }
}

.bottomButtonWrapper {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
