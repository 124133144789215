@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  text-align: left;
}

.backBtn {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.leftScreen {
  grid-column: 1 / -1;
  padding: 34px 27px;
  background: var(--iframe-bg);
  border-radius: v.$border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }

  @include m.breakpoint(1300px) {
    grid-column: 3 / 7;
  }

  .text {
    --text-color-primary: #{v.$lead};
  }

  .title {
    @include t.headline4;

    font-weight: 700;
    margin-bottom: 1em;

    .term {
      color: v.$magenta;
      text-transform: capitalize;
    }
  }

  .description {
    @include t.subtitle;

    margin-bottom: 24px;
  }
}

.rightScreen {
  grid-column: 1 / -1;
  padding: 34px;
  background: v.$magenta;
  border-radius: v.$border-radius;
  position: relative;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }

  @include m.breakpoint(1300px) {
    grid-column: 7 / -3;
  }

  .shareBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;

    li {
      width: 32px;
      height: 32px;
      margin-left: 8px;
      display: flex;
      align-items: center;
    }

    button {
      padding: 0 !important;
    }
  }

  .questions {
    --text-color-primary: #{v.$lead};
    position: relative;
    z-index: 2;

    li {
      @include t.body;
      padding: 16px 24px;
      border-radius: v.$border-radius;
      background: v.$white;
      margin: 8px 0px;
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.wrapper {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.video {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / 7;
  }

  @include m.breakpoint-max(v.$md) {
    // offset for grid gap
    margin-top: -24px;
  }
}

.form {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 7 / -3;
  }
}

.fieldGroup {
  flex-grow: 1;
  background-color: v.$magenta;
  padding: v.$padding;
  border-radius: v.$border-radius;
  margin-bottom: v.$padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  @include t.cta-text-bold;
  color: v.$white;
  margin-bottom: 1em;
  display: block;
}

.input {
  @include t.cta-text-bold;
  color: var(--text-color-secondary);
  width: 100%;
  background-color: v.$white;
  border-radius: v.$border-radius;
  padding: v.$padding;
  border-bottom: 3px solid v.$dark-magenta;
}
