@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.skipToMain {
  position: absolute;
  padding: 24px 20px;
  top: 20px;
  left: 20px;
  z-index: 100000;
  transform: translatex(-200%);
  transition: transform 0.3s;

  &:focus {
    transform: none;
  }
}
