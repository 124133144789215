@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.slide {
  transition: all 0.3s ease;
  align-self: stretch;
  height: 100%;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--resource-theme-color);
  overflow: visible;

  > *:not(.bg) {
    position: relative;
    z-index: 1;
  }

  :global(.light) & {
    --text-color-primary: #{v.$white};

    input {
      --background: #{v.$blue};
      --text-color-primary: #{v.$white};
    }
  }
}

.bg {
  position: absolute;
  top: var(--bg-top, 0);
  bottom: var(--bg-bottom, 0);
  left: var(--bg-left, 0);
  right: var(--bg-right, 0);
  display: flex;

  svg {
    width: 100%;
    height: 100%;
    * {
      fill: var(--resource-theme-color);
    }
  }
}

.imageWrapper {
  background: v.$white;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  img {
    max-width: 100%;
    max-height: 32px;
    margin: auto;
  }
}

.content {
  text-align: left;
}

.title {
  @include t.headline5($lg: 24px);

  line-height: 1.33 !important;
  font-size: 20px;
  margin: 24px 0 0;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  color: v.$white;
}

.description {
  @include t.body;
  @include m.richtext;

  font-weight: 400 !important;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  color: v.$white;

  p {
    letter-spacing: normal !important;
  }

  li {
    line-height: 1.2;
    margin: 8px;
  }
}

.bottom {
  // change the default hover effect of primary button
  button,
  a {
    position: relative;
    z-index: 1;

    @include m.hover {
      &:hover {
        --background: #{v.$white};
        --text-color-primary: #{v.$blue};
        box-shadow: none !important;
      }
    }
  }
}
