@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.card {
  @include m.indeed-shadow($static: true);

  --text-color: var(--text-color-secondary);
  border-radius: v.$border-radius;
  background-color: var(--card-bg-primary);
  text-align: left;
  padding: var(--card-pad, 20px);
  position: relative;

  &.backgroundImage {
    --text-color: #{v.$white};
    background-size: cover;
    background-clip: border-box;
  }

  &.collapsed {
    background-image: none;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.eyebrow {
  @include t.eyebrow;
  color: var(--text-color);
  font-weight: var(--eyebrow-weight, 700);
}

.title {
  @include t.headline4;
  margin-bottom: v.$padding;
  color: var(--text-color);
}

.description {
  margin-bottom: 1.6em;

  p {
    color: var(--text-color);
  }
}

.rotationOuter {
  display: table;
}

.rotationInner {
  padding: 50% 0;
  height: 0;
}

.collapsedTitle {
  display: block;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-100%);
  margin-top: -50%;
  white-space: nowrap;
}

.cta {
  align-self: flex-start;
}
