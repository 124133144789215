@use 'mixins' as m;
@use 'variables' as v;
@use 'typography' as t;

.wrapper {
  @include t.headline4;

  font-weight: 400;
  position: relative;
  padding-left: 80px;
  font-size: 16px !important;
  display: inline-flex;
  align-items: center;
  letter-spacing: 1px;
  display: none;

  @include m.breakpoint(v.$md) {
    display: block;
  }
}

.button {
  text-decoration: underline;
  display: inline-flex;
  align-items: center;

  span {
    pointer-events: none;
  }
}

.caret {
  transform: rotate(0deg);
  margin-left: 8px;
  width: 1em;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      stroke: currentColor;
    }
  }

  .open & {
    transform: rotate(180deg);
  }
}

.menu {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 18px));
  background: var(--background);
  border-radius: 8px;
  border: 1px solid var(--text-color-primary);
  padding: 9px;

  li {
    white-space: nowrap;
    border-radius: 5px;

    @include m.hover {
      &:hover {
        background-color: var(--card-bg-third-hover);
      }
    }
  }
}

.item {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 9px;

  span {
    pointer-events: none;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
