@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  width: 40px;
  height: 40px;
}

.inner {
  @include t.cta-text;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: var(--icon-text-color);

  svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
