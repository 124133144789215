@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  padding: 20px 30px;
  border-radius: 18px;
  // max-width: 300px;
  min-height: 329px;
}

.title {
  @include t.headline5(20px, 26px, 30px);
  margin-bottom: 20px;
  color: v.$white;
  font-weight: 800;
}

.listWrapper {
  @include t.body;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 20px;
  li {
    list-style: disc;
  }
}
