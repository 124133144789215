@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.leftCol {
  grid-column: 1/-1;
  margin-top: 12px;

  @include m.breakpoint(v.$md) {
    grid-column: 1/5;
    padding-right: 24px;
  }

  .imageContainer {
    img {
      max-width: min(100%, 500px);
      max-height: 400px;
    }
  }
}

.rightCol {
  grid-column: 1/-1;
  margin-top: 12px;

  @include m.breakpoint(v.$md) {
    grid-column: 5/-1;
  }

  .text {
    @include m.richtext;
    @include t.body;
  }
}

.links {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;

  a {
    @include m.underline-hover-flash;
    @include t.body;
    margin-right: 12px;

    align-self: flex-start;
    --underline-color: #{v.$blue};
    color: v.$blue;
    margin-bottom: 8px;
  }
}
