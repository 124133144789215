@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.container {
  text-align: center;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }
  45% {
    transform: translateY(2.5em);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateY(2.5em);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

.ball {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: v.$magenta;
  animation: bounce 1s infinite;
  margin: 0 24px;
}
