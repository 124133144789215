@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

$descriptionPadding: 40px;

.wrapper {
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: var(--button-height);
  transition: all 0.25s;
  margin-top: 10px;
  margin-bottom: 10px;

  &.active {
    max-height: var(--container-height);
    transition: all 0.25s, max-width 0.45s;
  }

  &.past:not(.active) {
    * {
      color: v.$gray;
    }

    button {
      div div {
        background: v.$gray;
      }
    }
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transform: rotate(180deg);
    transition: none;
    transform-origin: center;
  }
  &.active {
    svg {
      transform: rotate(0deg);
    }
  }

  .subItem & {
    margin-bottom: 0 !important;
  }
}

.title {
  @include t.headline5;
  padding: var(--dropdown-pad, 20px) 20px;
  color: v.$lead;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: relative;

  @include m.breakpoint(v.$md) {
    padding: var(--dropdown-pad, 40px) 60px;
  }

  &[disabled] {
    pointer-events: none;
    font-style: italic;
  }

  .subItem & {
    @include t.cta-text;
    padding: var(--dropdown-pad, 1em) 0 var(--dropdown-pad, 1em) 20px;

    @include m.breakpoint(v.$md) {
      padding: var(--dropdown-pad, 1em) 0 var(--dropdown-pad, 1em) 40px;
    }
  }

  .titleInner {
    max-width: calc(100% - 48px);
  }
}

.titlePrefix {
  @include t.headline5;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  .line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 0.7em;
    height: 2px;
    background: v.$blue;
    transform: translateY(-50%);
    transition: all 0.25s ease;

    &.lineVertical {
      transform: translateY(-50%) rotate(90deg);

      .active.headerWrapper & {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.contentWrapper {
  @include t.faq-body;
  overflow: hidden;
  padding: 0 20px 40px;
  color: v.$lead;
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
  visibility: hidden;
  transition: all 0.15s ease-out;

  &.active {
    transition: all 0.25s 0.2s ease-out;
    transform: translateY(0px);
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  @include m.breakpoint(v.$md) {
    padding: 0 60px 40px;
  }

  .subItem & {
    padding: 0 0 20px 20px;

    @include m.breakpoint(v.$md) {
      padding: 0 0 20px 40px;
      margin-right: -40px;
    }
  }
}

.content {
  @include m.richtext;
  max-width: 681px;

  ul {
    li:first-of-type {
      margin-top: 0;
    }
  }
}
