@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.mediaWrapper {
  width: 100%;
  position: relative;
  background: var(--text-color-primary);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 16px;

  @include m.breakpoint(v.$md) {
    padding: 24px;
  }

  :global(.light) & {
    background: v.$white;
  }
}

.header {
  margin-bottom: 40px;

  @include m.breakpoint(v.$md) {
    margin-bottom: 60px;
  }
}

.inner {
  --title-max-width: 860px;

  &.video {
    @include m.breakpoint-max(v.$md) {
      @include m.container;
    }
  }
}

.title {
  @include t.cta-text-bold;

  text-transform: capitalize;
  margin: 24px 0 0;
  color: var(--text-color-secondary);
  font-size: 20px !important;
}

.buttons {
  display: flex;
  gap: 24px;
  margin-top: 30px;
  justify-content: center;

  @include m.breakpoint(v.$md) {
    margin-top: 48px;
  }

  @include m.breakpoint(v.$lg-xl) {
    justify-content: left;
  }

  @include m.breakpoint-max(v.$md) {
    button {
      width: 100%;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  @include m.breakpoint-max(v.$sm) {
    button {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.description {
  @include t.cta-text;

  color: v.$lead;
  margin-top: 24px;
  max-width: 780px;
}
