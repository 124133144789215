@use 'mixins' as m;
@use 'variables' as v;
@use 'typography' as t;

.hamburger {
  width: 67px;
  height: 67px;
  border-radius: 100%;
  border: 1px solid var(--outline-secondary);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  position: relative;
  transition: all 0.35s ease;
  --line-color: var(--text-color-primary);

  &.sticky:not(.open) {
    background: var(--background);
    box-shadow: 0px 6px 12px -3px rgba(0, 0, 0, 0.1);

    .event & {
      background: v.$white;
    }
  }

  @include m.breakpoint(v.$md) {
    width: 80px;
    height: 80px;
  }

  &.open {
    --line-color: var(--background);

    // keep above the modal
    z-index: v.$z-2;
    color: v.$blue;
    border: 1px solid var(--background);
  }

  .event & {
    @include m.breakpoint-max(v.$md) {
      background: v.$white;

      &.open {
        --line-color: #{v.$blue};
      }
    }
  }
}

.linesWrapper {
  width: 24px;
  height: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: rotate(0deg);

  .open & {
    // .line1,
    // .line3 {
    //   --line-color: var(--background);
    // }

    .line1 {
      transform: translateY(7px) rotate(45deg);
    }

    .line3 {
      transform: translateY(-7px) rotate(-45deg);
    }

    .line2 {
      opacity: 0;
    }
  }
}

.line3,
.line2,
.line1 {
  height: 2px;
  background-color: var(--line-color);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: v.$z-3;
  pointer-events: none;
  height: calc(100% - var(--safe-area-inset-bottom, 0));
  padding-bottom: var(--safe-sare-inset-bottom, 0);

  * {
    color: v.$blue;
  }

  &.visible {
    pointer-events: auto;
  }
}

.inner {
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: auto;
  padding: 55px min(7vw, 120px) 24px;
  z-index: v.$z-2;
  overflow: auto;
  background: var(--text-color-primary);
  display: flex;
  flex-direction: column;

  * {
    color: var(--background);
  }

  @include m.breakpoint(v.$md) {
    width: 70%;
    padding: 70px min(7vw, 120px) 48px;
  }

  @include m.breakpoint(v.$lg) {
    width: 50%;
  }
}

.scrim {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}

.links {
  @include t.cta-text;

  margin: 1em 0;
}

.socialLinks {
  display: flex;

  .social:not(:last-of-type) {
    margin-right: 16px;
  }

  .social {
    transition: opacity 0.3s ease;
    @include m.hover {
      &:hover {
        opacity: 0.85;
      }
    }
  }

  svg {
    path {
      fill: currentColor;
    }
  }
}

.internalLinks {
  padding-bottom: 1em;
}

.links {
  margin-top: 48px;
}

.logoWrapper {
  padding-top: 18px;
  border-top: 1px solid v.$light-gray;
  padding-bottom: 18px;
}

.linksWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// .addLinks {
//   @include t.body;

//   font-weight: 500;
//   display: flex;
//   flex-direction: column;
//   position: relative;

//   @include m.breakpoint(v.$xl) {
//     flex-direction: row;
//   }

//   a {
//     margin-right: 16px;
//     display: inline-flex;
//     align-items: center;
//     line-height: 1;
//   }

//   .icon {
//     display: inline-flex;
//     padding: 0 8px;
//   }
// }

// .addLeft,
// .addRight {
//   display: flex;
//   align-items: center;
// }

// .addLeft {
//   @include m.breakpoint(v.$xl) {
//     border-right: 1px solid v.$light-gray;
//   }

//   a {
//     margin-bottom: 8px;
//   }

//   @include m.breakpoint-max(v.$md) {
//     a.signInBtn {
//       margin-bottom: 0;
//     }
//   }
// }

// .addRight {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;

//   @include m.breakpoint(v.$md) {
//     flex-direction: row;
//     align-items: center;
//   }
//   @include m.breakpoint(v.$xl) {
//     padding-left: 24px;
//   }

//   a {
//     margin-bottom: 8px;
//   }

//   a:first-of-type {
//     @include m.breakpoint-max(v.$md) {
//       order: 1;
//     }
//   }
// }

.link {
  @include t.menuItem;

  margin: 1em 0;
  color: var(--background);

  a {
    @include m.underline-hover-grow;
    --underline-color: var(--background);
  }

  * {
    color: currentColor;
  }
}

.signInBtn {
  position: absolute;
  bottom: 0;
  right: 0;

  @include m.breakpoint(v.$md) {
    position: relative;
  }
}

.logo {
  @include m.breakpoint-max(v.$md) {
    width: 68px;
    display: inline-block;
  }
}
