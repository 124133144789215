@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.item {
  @include t.cta-text;

  padding: 0.6em 1em;
  margin: 0.2em 1em;
  border-radius: 6px;
  list-style: none;
  transition: all 0.3s ease;
  color: var(--menu-item-color);

  &.hide {
    font-size: 2px;
  }

  &.focused {
    outline: none;
  }

  &:not(.hide).focused {
    background: var(--card-bg-third-hover);
    color: var(--menu-item-hover-color);
  }

  &:not(.hide).active {
    background: var(--card-bg-third);
    color: var(--menu-item-active-color);
  }

  &:not(.active):not(.hide) {
    @include m.hover {
      &:hover {
        background: var(--card-bg-third-hover);
        color: var(--menu-item-hover-color);
      }
    }
  }

  @include m.hover {
    &:hover {
      cursor: pointer;
    }
  }
}
