@use 'variables' as v;
@use 'mixins' as m;
@use 'typography' as t;

.video {
  @include m.hover {
    &:hover {
      cursor: pointer;
    }
  }

  @include m.hover {
    &:hover {
      .playBtn {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}

.sizerWrapper {
  border-radius: v.$border-radius;
  overflow: hidden;
  z-index: 1;
  border-bottom: 3px solid v.$dark-blue;
}

.playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: v.$z-2;
  width: 64px;
  height: 64px;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 0.6s ease, transform 0.4s ease;
  pointer-events: none;

  @include m.breakpoint(v.$md) {
    width: 120px;
    height: 120px;
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.image {
  opacity: 0;
  transition: opacity 1s ease 0.3s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
