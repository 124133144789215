@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

@mixin events-layout {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.container {
  display: flex;
  flex-direction: column;
  --card-shadow: #{v.$white};

  :global(.light) & {
    --card-shadow: #{v.$indigo};
  }
}

.headerWrapper {
  @include events-layout;
}

.eventsWrapper {
  @include events-layout;

  // allow events to take more space
  @include m.breakpoint(v.$lg) {
    grid-column: 1 / -1;
  }

  @include m.breakpoint(v.$xl) {
    grid-column: 3 / -3;
  }

  @include m.breakpoint-max(v.$lg) {
    overflow: scroll;
    margin-left: calc(-40px - (100vw - 344px) / 12 - 24px);
    margin-right: calc(-40px - (100vw - 344px) / 12 - 24px);
  }

  @include m.breakpoint-max(v.$md) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.events {
  display: flex;
  justify-content: center;
  margin-left: -11px;
  margin-right: -11px;

  @include m.breakpoint-max(v.$lg) {
    width: fit-content;
    padding-right: calc(40px + (100vw - 344px) / 12 + 24px);
    padding-left: calc(40px + (100vw - 344px) / 12 + 24px);
  }

  @include m.breakpoint-max(v.$md) {
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.gallery {
  @include events-layout;

  margin-top: 64px;
}
