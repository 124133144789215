@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  width: 100%;
  height: 100%;

  @keyframes confdash {
    0% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 500;
      transform: translate(-30px, 30px);
      opacity: 0;
    }
    2% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 500;
      transform: translate(-30px, 30px);
      opacity: 0;
    }
    35% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 900;
      transform: translate(-2px, 0px);
      opacity: 1;
    }

    85% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      transform: translate(1px, -5px);
      opacity: 1;
    }
    90% {
      stroke-dashoffset: 1000;
      stroke-dashoffset: 1000;
      transform: translate(2px, -8px);
      opacity: 0;
    }
    98% {
      stroke-dashoffset: 1000;
      stroke-dashoffset: 500;
      transform: translate(2px, -8px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes confa {
    0% {
      opacity: 0;
      transform: translate(-30px, 20px) rotate(0);
    }
    15% {
      opacity: 1;
      transform: translate(25px, -10px) rotate(60deg);
    }
    80% {
      opacity: 1;
      transform: translate(33px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(37px, -23px) scale(0.5) rotate(230deg);
    }
  }

  @keyframes confb {
    0% {
      opacity: 0;
      transform: translate(-30px, 20px) rotate(0);
    }
    12% {
      opacity: 1;
      transform: translate(25px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(33px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(37px, -23px) scale(0.5) rotate(240deg);
    }
  }

  @keyframes confc {
    0% {
      opacity: 0.7;
      transform: translate(-30px, 20px) rotate(0);
    }
    18% {
      opacity: 1;
      transform: translate(5px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(13px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(17px, -23px) scale(0.5) rotate(230deg);
    }
  }

  @keyframes confd {
    0% {
      opacity: 0.7;
      transform: translate(-20px, 20px) rotate(0);
    }
    18% {
      opacity: 1;
      transform: translate(-5px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(-8px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(-10px, -23px) scale(0.5) rotate(230deg);
    }
  }

  svg {
    --highlight: #{v.$white};

    :global(.dark) & {
      --highlight: #{v.$white};
    }

    :global(.light) & {
      --highlight: #{v.$magenta};
    }
    :global(.gold) {
      fill: #e0a961;
    }
    :global(.white) {
      fill: var(--highlight);
    }
    :global(.blue) {
      fill: #7abbdf;
    }

    :global(#strip) {
      fill: none;
      stroke: var(--highlight);
      stroke-width: 8;
      stroke-linecap: square;
      stroke-miterlimit: 10;
      animation: confdash 1.2s ease var(--delay, 0s);
    }

    :global(path),
    :global(circle) {
      animation-iteration-count: infinite !important;
      animation-duration: 1.2s !important;
      animation-delay: var(--delay, 0s) !important;
      opacity: 0;
    }

    :global(#r0) {
      transform-origin: 127px 176px;
      animation: confd 1.2s ease-in;
    }
    :global(#r1) {
      transform-origin: 120px 160px;
      animation: confd 1.2s ease-in var(--delay, 0s);
    }
    :global(#r2) {
      transform-origin: 133px 118px;
      animation: confd 1.2s ease-in;
    }

    :global(#r3) {
      transform-origin: 140.4px 92px;
      animation: confd 1.2s ease-in;
    }

    :global(#r4) {
      transform-origin: 152px 100px;
      animation: confd 1.2s ease-in;
    }

    :global(#b0) {
      transform-origin: 195px 232px;
      animation: confb 1.2s ease-in;
    }

    :global(#b1) {
      transform-origin: 195.2px 232.6px;
      animation: confb 1.2s ease-in;
    }

    :global(#b2) {
      transform-origin: 230.8px 219.8px;
      animation: confb 1.2s ease-in;
    }

    :global(#b3) {
      transform-origin: 262px 188.5px;
      animation: confb 1.2s ease-in;
    }

    :global(#b4) {
      transform-origin: 282.3px 170.6px;
      animation: confb 1.2s ease-in;
    }

    :global(#b5) {
      transform-origin: 224px 191px;
      animation: confb 1.2s ease-in;
    }

    :global(#k1) {
      transform-origin: 174.8px 183.4px;
      animation: confb 1.2s ease-in;
    }

    :global(#k2) {
      transform-origin: 178.9px 156.2px;
      animation: confb 1.2s ease-in;
    }

    :global(#k3) {
      animation: confc 1.2s ease-in var(--delay, 0s);
      transform-origin: 260px 150px;
    }

    :global(#k4) {
      transform-origin: 215px 140px;
      animation: confc 1.2s ease-in var(--delay, 0s);
    }

    :global(#k5) {
      transform-origin: 284px 120px;
      animation: confb 1.2s ease-in var(--delay, 0s);
    }

    :global(#k6) {
      transform-origin: 185px 101.2px;
      animation: confd 1.2s ease-in;
    }
    :global(#k7) {
      transform-origin: 200px 78px;
      animation: confd 1.2s ease-in var(--delay, 0s);
    }
    :global(#k8) {
      transform-origin: 206px 170px;
      animation: confa 1.2s ease-in var(--delay, 0s);
    }
  }
}
