@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  position: relative;
}

.header {
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 2/-2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3/-3;
  }
}

.iframe {
  border: none;
}

.container iframe {
  min-height: 1696px;

  @include m.breakpoint(v.$md) {
    min-height: 1100px;
  }

  @include m.breakpoint(v.$lg) {
    min-height: 965px;
  }
}
