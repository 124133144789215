@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  --shadow-color: var(--value-color);
  position: relative;
}

.input {
  position: absolute;
  height: 0;
  opacity: 0;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible + .label {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 5px;
  }
}

.label {
  @include m.button($round: true);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  background: v.$white;
  border-radius: 10px;
  text-align: left;
  transition: all 0.3s ease;

  @include m.hover {
    &:hover {
      @include m.box-shadow;
    }
  }

  &.selected {
    background: var(--value-color);

    @include m.hover {
      &:hover {
        box-shadow: none;
      }
    }
  }
}

.title {
  @include t.cta-text-bold;
  font-size: 18px;
  position: absolute;
  color: v.$white;
  opacity: 0;
  transition: opacity 0.125s ease;

  .selected & {
    opacity: 1;
  }
}

.message {
  @include t.body;
  cursor: pointer;
  flex-grow: 1;
  display: block;
  margin-right: 20px;
  color: var(--text-color-secondary);
  transition: opacity 0.125s ease;

  .selected & {
    opacity: 0;
  }
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 24px;
  height: 24px;
  border-radius: 100%;
  border: 2px solid v.$blue;
  transition: color 0.125s ease, background-color 0.125s ease,
    border-color 0.125s;

  .selected & {
    color: var(--value-color);
    background: v.$white;
    border-color: v.$white;
  }
}
