@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.button {
  @include t.cta-text-bold;

  outline: none;
  border: none;
  transition: all 0.3s ease;

  &.thin {
    padding-top: 0.85em !important;
    padding-bottom: 0.85em !important;
  }

  &:not(.outline):not(.outlineReversed) {
    &:focus {
      @include m.box-shadow;
    }

    @include m.hover {
      &:hover {
        @include m.box-shadow;
      }
    }
  }

  &.outline:not(.round),
  &.outlineReversed:not(.round) {
    &:focus {
      outline: none;
    }

    &:not(.focus):focus-visible {
      outline: 1px solid currentColor;
      outline-offset: 3px;
    }
  }

  &.round {
    @include m.button($round: true);

    display: inline-flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  &:not(.round) {
    @include m.button;

    display: inline-block;
    border-radius: 10px;
    padding: 1em 4em;
  }

  &.primary {
    background: var(--text-color-primary);
    color: var(--background);
  }

  &.whitebg {
    background: v.$white;
    color: v.$blue;
    --shadow-color: #{v.$indigo} !important;

    @include m.hover {
      &:hover {
        background: v.$blue;
        color: v.$white;
      }
    }
  }

  &.bluebg {
    background: v.$blue;
    color: v.$white;
    --shadow-color: #{v.$peach} !important;

    @include m.hover {
      &:hover {
        background: v.$white;
        color: v.$blue;
      }
    }
  }

  &.mono {
    background: v.$white;
    color: v.$lead;

    @include m.hover {
      &:hover {
        color: v.$blue;
      }
    }

    :global(.light) & {
      background: v.$dark-gray;
      color: v.$white;

      @include m.hover {
        &:hover {
          background: v.$blue;
        }
      }
    }
  }

  &.reversed {
    background: var(--card-bg-highlight);
    color: var(--text-color-primary);
  }

  &.outline {
    background: transparent;
    color: var(--text-color-primary);
    border: 1px solid var(--text-color-primary);

    @include m.hover {
      &:hover {
        background: var(--text-color-primary);
        color: var(--cta-outline-text);
      }
    }
  }

  &.outlineReversed {
    background: transparent;
    color: var(--text-color-secondary);
    border: 1px solid var(--text-color-secondary);
    padding: 15px 36px;
    @include m.hover {
      &:hover {
        background: v.$white;
        color: v.$blue;
        border-color: currentColor;
      }
    }
  }

  &.outlineReversedWhite {
    background: transparent;
    color: var(--text-color-primary);
    border: 1px solid var(--text-color-primary);

    @include m.hover {
      &:hover {
        background: v.$white;
        color: v.$blue;
        border-color: currentColor;
      }
    }
  }

  &.fullWidth {
    width: 100%;
    padding-left: unset;
    padding-right: unset;
    padding: 1em;
  }

  &[disabled] {
    background: v.$light-gray;
    color: v.$lead;

    :global(.light) & {
      background: v.$dark-gray;
      color: v.$white;
    }

    @include m.hover {
      &:hover {
        cursor: auto;
      }
    }

    &.whitebg,
    &.bluebg {
      background: v.$light-gray;
      color: v.$lead;
    }
  }
}
