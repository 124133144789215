@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.grid {
  position: relative;

  // // apply gradient fade at the bottom
  // &::before {
  //   content: ' ';
  //   position: absolute;
  //   bottom: 0;
  //   left: 18%;
  //   right: 18%;
  //   height: 40px;
  //   background: linear-gradient(to top, white, transparent);
  //   filter: blur(10px);
  //   z-index: 1;

  //   @include m.breakpoint-max(v.$md) {
  //     display: none;
  //   }
  // }
}

.header {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.inner {
  grid-column: 1 / -1;
  background: var(--iframe-bg);
  border-radius: v.$border-radius;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  // on mobile remove container and expand full length
  @include m.breakpoint-max(v.$md) {
    background: transparent;
    margin-left: -20px;
    margin-right: -20px;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }
}

.tabs {
  margin: 24px;

  @include m.breakpoint(v.$md) {
    margin: 36px;
  }

  .tab {
    @include t.cta-text-bold;

    padding-bottom: 0.7em;
    color: v.$gray;
    transition: all 0.3s ease;
    border-bottom: 2px solid transparent;

    &:first-of-type {
      margin-right: 36px;
    }

    &.active {
      color: var(--text-color-highlight);
      border-bottom: 3px solid currentColor;
    }

    &:not(.active) {
      @include m.hover {
        &:hover {
          color: var(--text-color-secondary);
        }
      }
    }
  }
}

.content {
  overflow: auto;
  margin-bottom: 7.5px;
  margin-top: 24px;

  @include m.breakpoint(v.$md) {
    // height: 460px;
    margin-top: 36px;
  }
}

.events {
  --eyebrow-weight: 300;
  padding: 0 24px 24px;

  @include m.breakpoint(v.$md) {
    @include m.cards(
      $column-desktop: 3,
      $column-tablet: 2,
      $desktop-bp: 1200px
    );
    padding: 0 36px 36px;
  }

  @include m.breakpoint-max(v.$md) {
    display: flex;
    width: fit-content;
  }

  .upcoming & {
    --shadow-color: #{v.$magenta};
  }

  .past & {
    --shadow-color: #{v.$dark-yellow};
  }
}

.card {
  margin-bottom: 24px;
  width: 100%;
  min-height: 300px;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);

  &:not(:last-of-type) {
    margin-right: 24px;
  }

  @include m.breakpoint(v.$md) {
    margin-right: 0;
  }

  @include m.breakpoint-max(v.$md) {
    min-width: 265px;

    @include m.breakpoint-max(v.$md) {
      :global(.light) & {
        --card-bg-primary: white;
      }
    }
  }
}

.cardTitle {
  @include t.headline5;

  --text-color: #{v.$indigo};
  font-weight: 800 !important;
  font-size: 20px !important;
  letter-spacing: -0.1px;
  margin-top: 0.5em;
  color: v.$indigo !important;
  text-transform: capitalize;

  @include m.breakpoint(v.$md) {
    font-size: 22px !important;
  }

  :global(.light) & {
    --text-color: #{v.$blue};
    color: v.$blue !important;
  }
}
