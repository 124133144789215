@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

$mobile: 560px;

.container {
  &.page {
    @include m.slice;
  }
}

li {
  font-size: 14px;
  text-align: left;

  @include m.breakpoint(v.$md) {
    font-size: 16px;
  }
}

.header {
  grid-column: 1 / -1;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }

  .page & {
    margin-top: v.$header-mobile;
    margin-bottom: 24px;

    @include m.breakpoint(v.$md) {
      margin-top: calc(v.$header-desktop - 48px);
    }
  }
}

.textWrapper {
  grid-column: 1 / -1;
  order: 2;

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / 8;
    order: 1;
    display: flex;
    flex-direction: column;
    // margin-right: -48px;

    .page & {
      align-self: top;
    }
  }

  @include m.breakpoint(v.$lg-xl) {
    grid-column: 3 / 7;
    margin-right: 0;
    // margin-right: -24px;
  }

  strong {
    font-weight: 800;
  }

  * {
    letter-spacing: normal !important;
  }
}

.imageWrapper {
  grid-column: 1 / -1;
  max-width: 520px;
  position: relative;
  align-self: baseline;
  margin: auto;
  order: 1;

  > div {
    margin-right: -22px;
  }

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;
  }

  @include m.breakpoint(v.$lg) {
    margin-top: 48px;
    grid-column: 8 / -1;
    order: 2;

    .page & {
      margin-top: 0;
    }

    > div {
      margin-right: 0;
    }
  }

  @include m.breakpoint(v.$lg-xl) {
    grid-column: 7 / -2;
  }

  .confetti {
    position: absolute;
    width: 60%;
    height: 60%;

    svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }

  .confettiBottom {
    bottom: 0px;
    left: 0px;
    margin-bottom: -20%;
    margin-left: -8%;
  }

  .confettiTop {
    top: 0;
    right: -10%;
    margin-top: -35%;
    transform: rotate(180deg);
    --delay: 0.7s;
  }
}

.description {
  @include m.richtext;
  @include t.subtitle;

  li {
    margin: 0.2em !important;
  }

  a {
    :global(.dark) & {
      color: v.$white !important;
    }
  }

  h3 {
    @include t.headline4;

    letter-spacing: 0.015em !important;
  }
}

.buttonWrapper {
  margin: 32px 0 24px;
  max-width: 180px;

  @include m.breakpoint-max(v.$lg) {
    margin: 32px auto;
  }

  @include m.breakpoint(v.$lg-xl) {
    margin: 40px 0;
  }
}

.footerCopy {
  @include t.body;
  width: 100%;
  flex: 1 1 auto;
  font-family: v.$font-family;

  @include m.breakpoint-max(v.$lg) {
    margin-bottom: 24px;
  }

  p {
    font-size: 13px;
    line-height: 1.6;
  }

  a {
    color: inherit;
    text-decoration: underline;
    word-break: break-all;
  }
}
