@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: v.$light-blue;
  border-radius: 10px;

  @include m.breakpoint(v.$md) {
    padding: 37px 25px 44px;
  }

  @include m.breakpoint(v.$xl) {
    padding: 37px 115px 44px;
  }
}

.backgroundContainer {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.background {
  width: 100%;
  height: 350px;
  max-height: none;
}

.selectedContainer {
  position: relative;
}

.selectedLabel,
.comboLabel {
  @include t.eyebrow;
  display: block;
  margin-bottom: 12px;
}

.selectedButton,
.comboInput {
  @include t.cta-text-bold;
  @include m.button($round: true);
  @include m.box-shadow($static: true);
  --shadow-color: rgba(0, 58, 155, 0.35);
  display: block;
  padding: 16px;
  width: 100%;
  background: v.$white;
  color: v.$lead;
  border: none;
}

.selectedLabel {
  @include m.breakpoint(v.$md) {
    text-align: center;
  }
}

.selectedButton {
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    @include t.cta-text-bold;
    color: v.$lead;
    text-align: center;

    @include m.breakpoint(v.$md) {
      font-size: 24px;
      font-weight: 800;
    }
  }
}

.selectedIcon {
  flex-shrink: 0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.combos {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 24px;

  @include m.breakpoint(v.$lg) {
    flex-direction: row;
  }
}

.combo {
  position: relative;

  @include m.breakpoint(v.$lg) {
    width: 50%;
  }
}

.comboInputWrapper {
  position: relative;
  transition: opacity 0.125s ease-in;

  .combo.disabled & {
    opacity: 0.66;
    pointer-events: none;
  }
}

.searchIcon {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translate(0, -50%);
}

.comboInput {
  text-align: left;
  padding-right: 0;
}

.comboPopover {
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: 8px;

  &::-webkit-scrollbar {
    background: transparent;
  }
}

.comboListbox {
  overflow-y: auto;
  max-height: 200px;
  padding: 12px;
  color: v.$lead;
  background: v.$white;
  border-radius: 10px;
}

.comboItem {
  @include t.cta-text;
  cursor: pointer;
  padding: 2px 4px;
  margin-bottom: 8px;
  color: v.$lead;
  text-align: left;
  border-radius: 5px;
  transition: background-color 0.1s ease-out;

  &:hover,
  &:global(.focused) {
    background: rgba(v.$lead, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:global(.selected) {
    font-weight: bold;
  }
}

.occupationInput {
  padding-left: 46px;
}
