@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
  position: relative;
  color: white;
}

.tooltipContainer {
  z-index: 1;
  position: absolute;
  top: -32px;
}

.tooltip {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  background: v.$magenta;
  border-radius: 10px;

  p {
    @include t.body;
    color: v.$white;
  }

  a {
    text-decoration: underline;
  }
}

.grid {
  position: relative;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1/-1;

  @include m.breakpoint(v.$md) {
    grid-column: 2/-2;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3/-3;
  }
}

.selectorContainer {
  z-index: 1;
  position: relative;
  width: 100%;
}

.selector {
  z-index: 1;
  position: relative;
  width: 100%;
}

.selectorConfirm {
  .selectorContainer + & {
    margin-top: 44px;
  }

  &:disabled {
    :global(.light) & {
      background: var(--text-color-primary);
      color: var(--background);
    }

    pointer-events: none;
    opacity: 0.66;
  }
}

.occupations {
  width: 100%;
}

.occupationsContainer {
  position: relative;
  width: 100%;

  .selectorContainer + &,
  .selectorConfirm + & {
    margin-top: 44px;
  }
}

.detailsModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.details {
  width: 100%;

  @include m.breakpoint(v.$md) {
    max-width: 900px;
  }
}

.modal {
  padding: 0;

  @include m.breakpoint(v.$md) {
    padding: 100px;
  }
}

.modalClose {
  display: none;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @include m.breakpoint(v.$md) {
    flex-direction: column;
  }
}

.feedback {
  margin-bottom: 50px;
  position: relative;
}

.exit {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  @include m.breakpoint(v.$tablet) {
    top: 10px;
    right: 10px;
  }
}

.footerLogoContainer {
  display: flex;
  margin-right: 32px;
  height: auto;
  flex-shrink: 0;

  @include m.breakpoint(v.$md) {
    height: 100%;
    flex-direction: column;
  }

  p {
    @include t.body;
    flex-grow: 1;
    font-weight: bold;
  }
}

.footerCopy {
  @include t.body;
  flex: 1 1 auto;

  p {
    font-size: 13px;
    line-height: 1.6;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
