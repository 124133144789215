@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  background: v.$white;
  overflow: hidden;
}

.bg {
  grid-column: 1 / -1;
  height: 50vw;
  position: relative;

  @include m.breakpoint(v.$md) {
    grid-column: 1 / span 5;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 25%;

    &::after {
      box-shadow: inset 0px 150px 70px -10px var(--theme-color),
        inset 0px -50px 50px -10px var(--theme-color);
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

.content {
  grid-column: 1 / -1;
  max-width: 780px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @include m.breakpoint(v.$md) {
    margin-top: 48px;
    grid-column: 4 / -1;
    margin-left: min(5vw, 100px);
    margin-top: v.$header-desktop;
  }
}

.back {
  @include t.body;

  margin-bottom: 48px;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;

  @include m.breakpoint-max(v.$md) {
    font-size: 16px;
  }

  .icon {
    height: 1em;
    margin-right: 6px;
    width: 1em;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.eyebrow {
  @include t.eyebrow;
}

.date,
.text {
  @include t.subtitle;
}

.date {
  margin-bottom: 48px;
}

.address {
  a {
    color: v.$blue;
    margin-top: 8px;
    display: inline-block;

    &:hover {
      color: v.$dark-blue;
    }
  }
}

.text {
  @include m.richtext;

  a {
    text-decoration: underline;
  }

  li {
    margin: 4px;
  }

  h3 {
    @include t.headline5;
    margin: 2em 0 0.4em;
  }
}

.description {
  margin-top: 2em;
}

.title {
  * {
    @include t.headline1;
  }

  margin: 0.4em 0 0.25em;
}

.subtitle {
  * {
    @include t.headline4;
  }

  margin: 4em 0 1em;
}

.iconWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  width: 280px;
  margin-top: 55px;
  --icon-width: 45px;
  --icon-pad: 0px;

  .iconWrapperInner {
    grid-column: span 1;
  }

  button {
    border: 1px solid v.$blue;
  }

  svg {
    path {
      stroke-width: 0.5;
      stroke: v.$blue;
    }
  }
}

.cta {
  margin: 30px 0 10px;
  @include m.breakpoint(v.$md) {
    width: auto;
    margin: 40px 0 10px;
  }
  width: 100%;
}

.virtualCta {
  margin-top: 12px;
  margin-bottom: 48px;
}

.dropdown {
  color: v.$blue;
  border: 1px solid currentColor;
  margin: 20px 0;
  --dropdown-pad: 24px;

  @include m.breakpoint(v.$md) {
    margin: 40px 0;
  }

  button {
    @include t.subtitle;
  }

  * {
    color: v.$blue;
  }

  svg {
    path {
      fill: currentColor;
    }
  }

  li {
    strong {
      display: inline-block;
      margin-bottom: 12px;
    }
  }
}

.formWrapper {
  width: 100%;
  display: flex;
}

.lyft {
  @include m.richtext;
  color: v.$white;
  --card-bg-primary: #{v.$magenta};
  --card-pad: 36px 20px 24px;
  --shadow-color: transparent;

  @include m.breakpoint(v.$md) {
    --card-pad: 36px 48px 24px;
  }

  .lyftCta {
    --shadow-color: #{v.$peach} !important;

    @include m.breakpoint-max(v.$md) {
      width: 100%;
    }
  }
}

.lyftTop {
  display: flex;

  @include m.breakpoint-max(v.$md) {
    flex-direction: column;
  }
}

.lyftTitle {
  @include t.cta-text-bold;
  margin-bottom: 12px;
  color: v.$white;
}

.lyftTitle,
.lyftDescription {
  font-size: 18px;
}

.lyftDescription {
  flex: 1;
  margin-right: 24px;
  line-height: 1;

  @include m.breakpoint-max(v.$md) {
    order: 2;
    margin-right: 0;
  }
}

.lyftIcon {
  background: v.$white;
  width: 98px;
  height: 98px;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 24px;

  @include m.breakpoint(v.$md) {
    margin-top: 24px;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    max-height: 32px;
    margin: auto;
  }
}

.lyftFaq {
  @include t.cta-text;
  @include m.underline-hover-flash;

  --underline-color: #{v.$white};
  display: block;
  color: v.$white;
  margin-top: 24px;
}
