@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.slide {
  position: relative;
  padding-left: 20px;
  flex: 0 0 65%;
  transition: all 0.3s ease, opacity 0.5s ease;
  margin-bottom: 20px;

  @include m.breakpoint(v.$md) {
    flex: 0 0 50%;
  }

  @include m.breakpoint(800px) {
    flex: 0 0 33.33333%;
  }

  @include m.breakpoint(1340px) {
    flex: 0 0 25%;
  }

  &.selected {
    filter: brightness(1);
  }

  &:not(.selected) {
    filter: brightness(0.9);

    :global(.light) & {
      filter: brightness(0.99);
    }
  }

  &:focus-within {
    filter: brightness(1);
    outline: none;
  }

  @include m.hover {
    &:hover {
      filter: brightness(1);
    }
  }

  > div {
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
  }
}

.slideInner {
  @include m.box-shadow;

  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 24px 24px 8px 24px;
  background: var(--text-color-primary);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.35s ease;
  --shadow-color: transparent;

  .selected & {
    --shadow-color: #{v.$magenta};
  }

  &:focus {
    --shadow-color: #{v.$magenta};
    outline: none;
  }

  @include m.hover {
    &:hover {
      --shadow-color: #{v.$magenta};
    }
  }

  :global(.light) & {
    background: v.$white;
  }
}

.imageWrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;

  .selected & {
    opacity: 1;
  }
}

.content {
  @include t.eyebrow;

  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  .partners & {
    min-height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

.title {
  margin: 12px 0 0;
  color: var(--text-color-secondary);
  transition: all 0.3s ease-out;
  line-height: 1.2;
  max-width: 160px;
  opacity: 0.85;

  .selected & {
    opacity: 1;
  }

  .partners & {
    margin: 12px 0 0;
    color: var(--text-color-highlight);
    text-align: center;
  }
}

.byline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  color: var(--text-color-secondary);

  span:first-of-type {
    flex: 1 0 auto;
    margin-right: 8px;
  }

  span:last-of-type {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    display: inline-flex !important;
    align-items: center;
  }
}
