// COLORS
$transparent: transparent;
// primary
$black: #000000;
$white: #ffffff;
$lead: #282828;
$dark-grey: #4d4d4d;
$dark-gray: $dark-grey;
$grey: #757575;
$gray: $grey;
$light-grey: #dedede;
$light-gray: $light-grey;
$lighter-gray: #eee;
$lighter-grey: $lighter-gray;

$indigo: #001c40;
$blue: #003a9b;
$dark-blue: #032677;

// secondary
$magenta: #c74289;
$dark-magenta: #ad3882;
$orange: #df7838;
$dark-yellow: #c98600;
$dark-teal: #358271;
$purple: #5644bf;
$green-blue: #1f5f78;
$teal: #207399;

// tertiary
$light-pink: #f3c7d9;
$peach: #f39e78;
$light-yellow: #e0a961;
$light-teal: #7bc0ae;
$light-purple: #b1abf4;
$light-blue: #7abbdf;

// BREAKPOINTS
$sm: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop-sm: 1200px;
$desktop: 1440px;
$md: $tablet;
$lg: $laptop;
$lg-xl: $desktop-sm;
$xl: $desktop;
$breakpoints: (
  sm: $sm,
  md: $md,
  lg-xl: $lg-xl,
  lg: $lg,
  xl: $xl,
);

// TYPE
$font-family: 'Indeed Sans', Helvetica, sans-serif;
$font-body: 'Noto Sans', Helvetica, sans-serif;

// SPACING
$padding: 20px;
$grid-gap: 8px;
$grid-gap-md: 16px;
$max-width: 1440px;
$gutter: 20px;
$gutter-md: 40px;
$slice-pad: 40px;
$slice-pad-md: 80px;
$slice-pad-lg: 160px;
$event-card-pad: 31px;

// ELEMENT
$header-desktop: 120px;
$header-mobile: 100px;
$border-radius: 10px;

// Z-INDEX
$hero-z: 2; // keep above the slices below
$pageline-z: 1;
$slice-z: 1;
$footer-z: 1;
$z-1: 8888;
$z-2: 7777;
$z-3: 6666;

// BORDERS
$border-width: 1px;
$border: $border-width solid;
$border-radius: 10px;
