@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.container {
	--text-color-primary: #{v.$white};
	padding: 32px;
	color: v.$white;
	background: v.$magenta;

	h4 {
		@include t.headline5;
		margin-bottom: 16px;
	}

	p {
		@include t.body;
	}

	ul {
		margin: 0 -10px -10px 0;
	}

	li {
		@include t.body;
		display: inline-block;
		padding: 5px 20px;
		margin: 0 10px 10px 0;
		background: white;
		color: v.$magenta;
		border-radius: 10px;
		font-size: 14px;
		font-weight: 500;
	}

	@include m.breakpoint(v.$md) {
		border-radius: 10px;
	}
}

.header {
	display: flex;
	align-items: flex-start;
	padding-bottom: 30px;

	h3 {
		@include t.headline4;
		flex-grow: 1;
	}
}

.close {
	padding: 8px;
	flex-shrink: 0;

  svg {
    transform: rotate(0deg);
    transition: transform 0.35s;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
}

.content {
	display: flex;
	flex-direction: column;
	gap: 40px;

	@include m.breakpoint(v.$md) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

.section {
	display: flex;
	flex-direction: column;
	gap: 36px;
}

.ctaContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 32px;
}

.cta {
	--shadow-color: #{v.$blue};
	--background: #{v.$blue};
}

.demand {
	h4 {
		font-size: 14px;
		margin-bottom: 10px;
	}
}

.meterContainer {
	display: flex;
	gap: 10px;
	margin-bottom: 16px;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}
}

.star {
	display: block;
	width: 32px;
	height: 12px;
	background: v.$white;
	opacity: 0.25;
	border-radius: 10px;

	.meterContainer:global(.low) & {
		&:nth-child(-n + 1) {
			opacity: 1;
		}
	}

	.meterContainer:global(.medium-low) &,
	.meterContainer:global(.lower-middle) & {
		&:nth-child(-n + 2) {
			opacity: 1;
		}
	}

	.meterContainer:global(.medium) &,
	.meterContainer:global(.middle) & {
		&:nth-child(-n + 3) {
			opacity: 1;
		}
	}

	.meterContainer:global(.medium-high) &,
	.meterContainer:global(.upper-middle) & {
		&:nth-child(-n + 4) {
			opacity: 1;
		}
	}

	.meterContainer:global(.high) & {
		&:nth-child(-n + 5) {
			opacity: 1;
		}
	}
}

.aria {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
}