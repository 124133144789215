@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  margin-top: v.$slice-pad-md;
  margin-bottom: v.$slice-pad-md;
}

.grid {
  display: flex;
  flex-direction: column;

  @include m.breakpoint(540px) {
    flex-direction: row;
  }

  @include m.breakpoint(v.$md) {
    display: grid;
  }
}

.headline {
  * {
    @include t.headline2;

    :global([lang='es']) & {
      @include t.headline2-sm;
    }
  }

  color: var(--text-color-primary);
  text-align: center;
  margin-bottom: 44px;

  @include m.breakpoint(540px) {
    text-align: left;
    flex: 1;
  }

  @include m.breakpoint(v.$md) {
    grid-column: 2 / -2;

    * {
      max-width: 100%;
    }
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -3;
  }

  @include m.breakpoint(1200px) {
    grid-column: 3 / span 4;
    margin-bottom: 0;
  }
}

.infoWrapper {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 59px;

  @include m.breakpoint(540px) {
    flex: 1;
  }

  @include m.breakpoint(v.$md) {
    grid-column: 2/ -1;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include m.breakpoint(v.$lg) {
    grid-column: 3 / -1;
  }

  @include m.breakpoint(1200px) {
    grid-column: 7 / -1;
    flex-direction: column;
  }
}
.statWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include m.breakpoint-max(540px) {
    width: 240px;
    margin: auto;
  }

  @include m.breakpoint(v.$md) {
    flex: 0 0 40%;
  }

  @include m.breakpoint(1200px) {
    flex: 1;
  }
}

.iconContainer {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--text-color-primary);

  :global(.light) & {
    background: v.$white;
    box-shadow: 0px 0px 2px 1px rgba(v.$blue, 0.1);
  }
}
.numberContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.number {
  @include t.headline2;
  color: var(--text-color-primary);
}
.description {
  @include t.subtitle;
  color: var(--text-color-primary);
  font-weight: bold;
}
