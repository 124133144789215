@use 'mixins' as m;
@use 'typography' as t;
@use 'variables' as v;

.wrapper {
  z-index: v.$hero-z;
  position: relative;
  margin-bottom: 48px;
  transition: opacity 0.35s ease;
}

.content {
  min-height: max(580px, 100vh);
  text-align: center;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;

  @include m.breakpoint(v.$md) {
    padding-left: 48px;
    padding-right: 48px;
    min-height: 56.25vw;
  }

  @include m.breakpoint(v.$lg) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @include m.breakpoint(1280px) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.text {
  padding-top: calc(v.$header-desktop + 15vh);

  a {
    color: var(--text-color-primary);
    text-decoration: underline;
  }

  @media (orientation: portrait) {
    padding-top: 30vh;
  }
}

.title {
  @include t.headline1;

  max-width: 1130px;
  margin: auto;

  :global([lang='es']) & {
    @include t.headline2;

    line-height: 1.05 !important;
  }
}

.description {
  @include t.subtitle;

  max-width: 724px;
  margin: 1.8em auto 0;
  padding-left: 20px;
  padding-right: 20px;

  * {
    @include t.subtitle;

    text-align: center;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(to top, transparent 10%, black 90%);
  mask-image: linear-gradient(to top, transparent 10%, black 90%);

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(to top, var(--background), transparent);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @include m.breakpoint-max(v.$md) {
    right: auto;
    bottom: auto;
    width: 1440px;
    height: 100vh;
    transform: translateX(-50%);
  }

  @include m.breakpoint(v.$md) {
    right: 0;
    bottom: 0;
  }
}

.selectorWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.selector {
  padding-top: 48px;

  @include m.breakpoint-max(v.$md) {
    padding-top: v.$header-mobile;
    margin-top: -48px;
  }
}

.text {
  transition: opacity 0.5s ease 0.2s;
}

.checkpoint {
  top: calc(100% + 32px);
  left: 50%;
}
